import { Component, OnInit, Input } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { ModalService } from 'Shared/services/modal.service';
import { AccountBurgerMenuComponent } from 'Shared/components/modals/account-burger-menu/account-burger-menu.component';
import { User, UserService } from 'Shared/services/user.service';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { PurchaseService, Purchase } from 'Checkout/services/purchase.service';
import { BehaviorSubject } from 'rxjs';
import { BasketModalComponent } from 'Shared/components/modals/basket-modal/basket-modal.component';
import { NavContentsModelService } from 'Shared/models/nav-contents-model.service';
import { CountryService } from 'Shared/services/country.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { LocationService } from 'Shared/services/location.service';
import { ConfigService } from 'Shared/services/config.service';
import { FilterService } from 'Checkout/services/filter.service';
import { WindowRefService } from 'Shared/services/window.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { NavItem } from 'Shared/classes/nav-item';
import { FeatureBannerDisplayService } from 'Shared/services/feature-banner-display.service';
import { MegaNavMobileComponent } from 'Shared/components/mega-nav-mobile/mega-nav-mobile.component';
import { ConfigModelService } from 'Shared/models/config-model.service';
import { ContentService } from 'Shared/services/content.service';
import { ContentSegment } from 'Shared/models/segment-model.service';
import { FeaturesService } from 'Shared/services/features.service';
import { DiscountService } from 'Checkout/services/discount.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-nav',
  templateUrl: './nav.component.html'
})
export class NavComponent implements OnInit {
  @Input() links: Object[] = [];

  @Input() displayNav: boolean;
  @Input() displayShop: boolean;
  user$: BehaviorSubject<User>;
  purchase$: BehaviorSubject<Purchase>;
  pageCategory: string;
  site: string;
  shippingTo: number;
  showCreditOverlay: boolean = false;

  show: boolean = true;

  parentNav: any[];
  megaNavMobileShow: boolean = false;

  showFilters$: BehaviorSubject<boolean> = this.filterService.showFilters$;
  isFullClosure: boolean = false;
  isStickyHeader: boolean = false;
  currentCurrency: string;

  showDeliveryTracking: boolean;
  isInitialPage: boolean = true;
  visualCategoryNavEnabled: boolean;
  showDiscountBar$: BehaviorSubject<boolean> = this.discountService.showDiscountBar$;
  hideNavigation: boolean = false; // a check to see if the user has started checkout by navigating to 'checkout.start'
  hideMegaNavigation: boolean = false; // a check to see if the user has started checkout by navigating to 'checkout.start'

  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private windowRef: WindowRefService,
    private stateService: StateService,
    private purchaseService: PurchaseService,
    private navContents: NavContentsModelService,
    private countryService: CountryService,
    private locationService: LocationService,
    public experimentService: ExperimentsService,
    private configService: ConfigService,
    private filterService: FilterService,
    private analyticsService: AnalyticsService,
    public featureDisplayService: FeatureBannerDisplayService,
    private remoteConfig: ConfigModelService,
    private contentService: ContentService,
    private featuresService: FeaturesService,
    private discountService: DiscountService,
    private optimizelyService: OptimizelyService
  ) {
    this.purchase$ = this.purchaseService.purchase$;
    this.site = this.configService.getConfig().site;

    this.shippingTo = this.countryService.forShipping.id;
  }

  ngOnInit(): void {
    this.shippingTo = this.countryService.forShipping.id;
    this.user$ = this.userService.user$;
    this.visualCategoryNavEnabled = this.featuresService.getFeature('VISUAL_NAV');
    this.remoteConfig
      .hasRemoteConfig()
      .then((config): void => {
        const showClosureConfig = config['is_full_closure_mode'] || {};
        this.countryService.forShipping$.subscribe((country): void => {
          this.isFullClosure = showClosureConfig[country.id];
        });
      })
      .catch((): void => {});

    // Any time the shipping country changes reload the nav
    this.countryService.forShipping$.subscribe((data): void => {
      this.initNavItems();
      this.currentCurrency = data.currencyCode;
      this.visualCategoryNavEnabled = this.featuresService.getFeature('VISUAL_NAV');
      // On Shipping Country Change hide discount bar
      this.discountService.setShowDiscountBarValue(false);
    });

    this.stateService.onSuccess$.subscribe((transition): void => {
      if (transition.to.data && transition.to.data.pageCategory) {
        this.pageCategory = transition.to().data.pageCategory;
      }
      if (this.locationService.getCurrentParams().openBasket) {
        this.basketMenu();
      }
      this.isInitialPage = this.stateService.isInitialPage;
      this.showDeliveryTracking = this.shouldShowDeliveryTracking();

      // If the current state is checkout started then we want to hide the nav and header

      this.hideNavigation = this.stateService.getCurrent().name === 'checkout.start';
      this.setStickyOffset();
    });

    // Hide Mega Nav bar for the Help centre
    this.hideMegaNavOnHelpCentrePages();
    this.stateService.onSuccess$.subscribe((event): void => {
      this.show = !event.to.name?.startsWith('help');
    });

    this.contentService.contentSegmentsDidChange$.subscribe((): void => {
      this.initNavItems();
      this.show = false;
      setTimeout((): void => {
        // Hide Mega Nav bad for the Help centre
        this.hideMegaNavOnHelpCentrePages();
      }, 10);
    });

    this.windowRef.nativeWindow.addEventListener('popstate', this);

    this.showDeliveryTracking = this.shouldShowDeliveryTracking();

    setTimeout((): void => {}, 10);
  }
  /**
   * Show the delivery tracking on mobile homepage only
   */
  shouldShowDeliveryTracking(): boolean {
    return this.stateService.getCurrent().name === 'homepage';
  }

  /**
   * Track account menu opening and closing
   * @param eventName
   */
  trackAccountBurgerMenuAction(eventName: 'menuOpened' | 'menuClosed'): void {
    const config = this.configService.getConfig();
    const user = this.userService.getUser();
    this.analyticsService.trackInHeap(eventName, {
      brandId: config.brandId,
      locale: config.locale,
      loggedInUser: user.isLoggedIn(),
      modalType: 'accountBurgerMenuModal',
      registeredUser: user.isLoggedIn() || user.email.hasRegistered || user.email.hasOrdered,
      shippingCountryId: this.countryService.forShipping.id,
      site: config.site
    });
  }

  /**
   * Open account burger menu
   */
  accountMenu(): Promise<void> {
    this.analyticsService.track('component.nav.account');
    this.trackAccountBurgerMenuAction('menuOpened');
    return this.modalService
      .show(AccountBurgerMenuComponent, {
        class: 'sideMenu left autoWidth fullHeight',
        animationDirection: 'left'
      })
      .then((): void => {
        this.trackAccountBurgerMenuAction('menuClosed');
      })
      .catch((): void => {
        this.trackAccountBurgerMenuAction('menuClosed');
      });
  }

  basketMenu(): void {
    this.analyticsService.track('component.nav.basket');
    this.modalService
      .show(BasketModalComponent, {
        class: 'sideMenu right autoWidth fullHeight',
        animationDirection: 'right'
      })
      .catch((): void => {});
  }

  openBurgerMenu(isMore: boolean = false): Promise<any> {
    if (isMore) {
      this.analyticsService.track('component.nav.more');
    } else {
      this.analyticsService.track('component.nav.side');
    }

    this.megaNavMobileShow = true;
    return this.modalService
      .show(MegaNavMobileComponent, {
        modalName: `${isMore ? 'more' : 'main'}BurgerMenu`,
        class: 'sideMenu left autoWidth--mega-nav generalBurgerMenu fullHeight',
        animationDirection: 'left',
        underNav: true
      })
      .then((): void => {
        this.megaNavMobileShow = false;
      })
      .catch((): void => {
        // If error or promise is cancelled set to false
        this.megaNavMobileShow = false;
      });
  }

  navInteractionTracking(parentNavItem: string, childNavItem?: string): void {
    this.optimizelyService.trackEvent('nav_interaction');
    this.analyticsService.trackInHeap('navInteraction', {
      mainNavItem: parentNavItem,
      secondarySubNavItem: childNavItem ? childNavItem : undefined,
      navStyle: 'inline'
    });
  }

  closeBurgerMenu(): void {
    this.megaNavMobileShow = false;
    this.modalService.hideAllModals();
  }

  /**
   * Should lauch the modal, will trigger the show via products-list component
   */
  launchMobileFilters(): void {
    this.filterService.shouldLaunchFiltersModal();
  }

  /**
   * @description om account click event
   * @returns {Promise<void>}
   */
  accountClicked(): Promise<void> {
    return this.user$.getValue().isLoggedIn() ? this.accountMenu() : this.showLoginModal();
  }

  /**
   * Show the login modal
   */
  showLoginModal(): Promise<void> {
    this.analyticsService.track('component.nav.account');
    return this.modalService
      .show(AuthModalComponent, {
        initialState: { origin: 'modal', fullOrigin: 'webAccountNav' },
        trackingKey: 'auth-modal-nav'
      })
      .then((): Promise<void> | void => {
        const currentState = this.stateService.getCurrent();
        const isCheckout = (currentState.name as string).indexOf('checkout') > -1;

        if (!isCheckout) {
          this.windowRef.nativeWindow.scroll({ top: 0, left: 0, behavior: 'auto' });
        }

        const isHomepage = (currentState.name as string).indexOf('homepage') > -1;

        if (isHomepage && this.featuresService.getFeature('OPEN_ACCOUNT_MENU')) {
          return this.accountMenu();
        }
      })
      .catch((): void => {}); // Stops empty promise errors
  }

  setStickyOffset(): void {
    const serviceBar = this.windowRef.nativeWindow.document.querySelector('bw-service-bar');
    const discountBar = this.windowRef.nativeWindow.document.querySelector('bw-discount-bar');
    const navBar = this.windowRef.nativeWindow.document.querySelector('bw-nav');

    if (!navBar) {
      return;
    }

    // @media only screen and (max-width: 768px)

    navBar.setAttribute(
      'style',
      `top:-${
        (serviceBar ? serviceBar.getBoundingClientRect().height : 0) + (discountBar ? discountBar.getBoundingClientRect().height : 0)
      }px`
    );
  }

  /**
   * Init the nav items
   */
  initNavItems(): Promise<any> {
    return Promise.all([this.navContents.getNav(this.countryService.forShipping), this.contentService.getContentSegments()]).then(
      ([navItems, segments]): void => {
        this.links = this.excludeNavItemsForSegments(segments, navItems.links);
      }
    );
  }

  creditOverlayToggle(): void {
    this.showCreditOverlay = !this.showCreditOverlay;
  }

  trackFilterEducationClose(): void {
    this.analyticsService.track('nav.filter-education.closed');
  }

  // TODO refactor nav to avoid these awful timing races
  // Piv ID: #175935012
  serviceBarDidLoad(): void {
    setTimeout((): void => {
      this.setStickyOffset();
    }, 2500);
  }

  /**
   * Hide mega nav if pages belong to Help centre
   */
  private hideMegaNavOnHelpCentrePages(): void {
    const currentState = this.stateService.getCurrent().name as string;
    this.show = !currentState?.startsWith('help');
  }

  /**
   * Exclude any gift cards based on the user's segment
   * @param segments
   * @param config
   * @param covers
   */
  private excludeNavItemsForSegments(segments: ContentSegment[], items: NavItem[]): NavItem[] {
    const toExclude = segments.reduce((acc, segment): any[] => {
      acc.push(...segment.excluded_tags);
      return acc;
    }, []);

    // Loop though items, then sections, then links
    const navItems = items
      .slice()
      .filter((item): boolean => !toExclude.find((t): boolean => item.tags.indexOf(t) > -1))
      .map((item): NavItem => {
        item.sublinks = item.sublinks.filter((section): boolean => !toExclude.find((t): boolean => section.tags.indexOf(t) > -1));
        return item;
      });

    this.links = navItems;
    setTimeout((): void => {}, 10); // Due to the initNavItems not being in a promise chain, we need to force a digest

    return navItems;
  }
}
