import { Injectable } from '@angular/core';
import { ClaimedReward, LoyaltyMembership } from 'Shared/classes/loyalty';
import { LoyaltyModelService } from 'Shared/models/loyalty-model.service';
import { UserService } from 'Shared/services/user.service';
import { FeaturesService } from 'Shared/services/features.service';
import { StateService } from 'Shared/services/state.service';
import { Price } from 'Shared/classes/price';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {
  constructor(
    private loyaltyModelService: LoyaltyModelService,
    private userService: UserService,
    private featuresService: FeaturesService,
    private stateService: StateService
  ) {}

  /**
   * @description Check if user is a loyalty scheme member and rewards feature is active
   * @returns {boolean}
   */
  get isLoyaltySchemeMember(): boolean {
    // Check if feature is active
    const user = this.userService.getUser();
    return this.isRewardsFeatureActive && user?.loyaltySchemeMembershipId !== undefined;
  }

  /**
   * @description Check if rewards feature is active
   * @returns {boolean}
   */
  get isRewardsFeatureActive(): boolean {
    const showRewards = this.stateService.getCurrent()?.params?.['showRewards'] || this.stateService.getInitial()?.params?.['showRewards'];
    return (this.featuresService.getFeature('REWARDS') || showRewards) ?? false;
  }

  /**
   * @description Retrieve loyalty membership
   * @param {number} id -> LoyaltyMemberShipSchemeId from user
   * @returns {Promise<LoyaltyMembership>}
   */
  public retrieveLoyaltyMembershipPointBalance(id: number): Promise<LoyaltyMembership> {
    return this.loyaltyModelService.retrieveLoyaltyMembershipPointBalance(id);
  }

  /**
   * @description Claim loyalty membership vaucher
   * @param {number} id
   * @param {number} rewardId
   * @returns {Promise<ClaimedReward>}
   */
  public claimLoyaltyMembershipVoucher(id: number, rewardId: number): Promise<ClaimedReward> {
    return this.loyaltyModelService.claimLoyaltyMembershipVoucher(id, rewardId);
  }

  /**
   * @description Join loyalty membership
   * @returns {Promise<LoyaltyMembership>}
   */
  public joinLoyaltyMembership(): Promise<LoyaltyMembership> {
    return this.loyaltyModelService.joinLoyaltyMembership().then(async (loyaltyMembership): Promise<LoyaltyMembership> => {
      await this.userService.refreshUser();
      return Promise.resolve(loyaltyMembership);
    });
  }

  /**
   * @description Get loyalty welcome points
   * @returns {number | undefined}
   */
  public getLoyaltyWelcomePoints(): Promise<number | undefined> {
    if (this.isRewardsFeatureActive) {
      return this.loyaltyModelService.getLoyaltyWelcomePoints();
    }

    return Promise.resolve(undefined);
  }

  /**
   * @description Calculate total order loyalty points
   * @param {Price} price
   * @returns {number}
   */
  public calculateTotalOrderLoyaltyPoints(price: Price): number {
    // Value of points per pence
    const pointsPerUnit: number = this.featuresService.getFeature('REWARDS_VALUE_PER_PENCE');
    return Math.ceil(price.price * pointsPerUnit);
  }
}
