import { Injectable } from '@angular/core';
import { Address } from 'Shared/classes/address';
import { DeliveryDate } from 'Shared/classes/delivery-date';
import { DeliveryDateModelService } from 'Shared/models/delivery-date-model.service';
import { Product } from 'Shared/classes/product';
import * as dayjs from 'dayjs';
import { Delivery } from 'Shared/classes/delivery';
import { Error } from 'Shared/classes/error';
import { t } from 'Shared/utils/translations';
import { ConfigService } from 'Shared/services/config.service';
import { DateUtilsService } from 'Shared/utils/date-utils.service';
import { CountryService } from './country.service';
import { FeaturesService } from './features.service';
import { ExperimentsService } from './experiments.service';
import { ShippingOption } from '../classes/shipping-option';
import { PeakShippingService } from 'Shared/services/peak-shipping.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryDateService {
  public peakFreeShipping: boolean = false;

  constructor(
    private deliveryDateModel: DeliveryDateModelService,
    private configService: ConfigService,
    private countryService: CountryService,
    private featuresService: FeaturesService,
    private experimentsService: ExperimentsService,
    private peakShippingService: PeakShippingService
  ) {}

  /**
   * Get the dates within a product's deliverable from/to date
   * @param product
   * @param requestedDate
   */
  static dateRangeForProduct(
    product: Product,
    serverTime: dayjs.Dayjs,
    idealDate: dayjs.Dayjs
  ): {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  } {
    let start: dayjs.Dayjs;
    let end: dayjs.Dayjs;

    if (idealDate.isAfter(product.deliverableTo)) {
      end = product.deliverableTo.clone().add(1, 'day');
      start = end.clone().subtract(15, 'day').startOf('month');
    } else {
      const validDate = product.deliverableFrom.isBefore(serverTime) ? serverTime : product.deliverableFrom;
      start = validDate.clone().startOf('month');
      end = start.clone().endOf('month').add(10, 'day');
    }

    return { start, end };
  }

  /**
   * Get the default delivery date for an address and product
   * @param product
   * @param initialAddress
   * @param initialDate
   */
  getDefaultDeliveryDate(product: Product, initialAddress?: Address, initialDate?: dayjs.Dayjs): Promise<DeliveryDate> {
    const addressToUse = initialAddress || new Address();
    addressToUse.country = addressToUse.country || this.countryService.forShipping;

    // prettier-ignore
    const serverTime = this.configService.getConfig().serverTime.clone().startOf('day');

    let dateToUse = initialDate ?? serverTime;

    // If the initial date is BEFORE the servertime, use serverTime...
    if (initialDate <= serverTime) {
      dateToUse = serverTime;
    }
    // ... if the serverTime is before the first date we can deliver the product, use deliverableFrom
    if (initialDate <= product.deliverableFrom) {
      dateToUse = product.deliverableFrom;
    }

    const { start, end } = DateUtilsService.dateRange(dateToUse);

    // First get dates for our normal range
    return this.getDates(addressToUse, product, serverTime, start, end)
      .then((dates): Promise<DeliveryDate[]> => {
        // Filter out dates that dont have any shipping options as well as shipping options that are cut off
        const datesWithShipping = this.filterForAvailableDates(dates);
        if (datesWithShipping && datesWithShipping.length) {
          return Promise.resolve(datesWithShipping);
        }

        // If we can't find any dates requested, attempt to look again, but for the product's date range
        const range = DateUtilsService.dateRangeForProduct(product, serverTime, dateToUse);

        return this.getDates(addressToUse, product, serverTime, range.start, range.end).then((rDates): DeliveryDate[] =>
          // Filter out dates that dont have any shipping options as well as shipping options that are cut off
          this.filterForAvailableDates(rDates)
        );
      })
      .then((datesWithShipping): Promise<DeliveryDate> | DeliveryDate => {
        if (!datesWithShipping || !datesWithShipping.length) {
          const errd = new Error({
            message: t('js.components.error-msg.delivery-dates'),
            code: 'noDeliveryError'
          });
          return Promise.reject(errd);
        }

        // If we have requested a specific date...
        if (initialDate) {
          const foundDate = datesWithShipping.find((d): boolean => d.date.format('YYYY-MM-DD') === initialDate.format('YYYY-MM-DD'));

          // ... and we've found a match
          if (foundDate) {
            return Promise.resolve(foundDate);
          }
        }

        // Check if current SKU has free shipping before valentines day
        if (this.featuresService.getFeature('PEAK_DELIVERY_OPTIONS') && this.peakShippingService.inFreeDeliveryExperiment()) {
          this.freeDateIsBeforePeakCutOff(datesWithShipping, dateToUse);
        }

        const defaultToFree = this.configService.getConfig().web_default_to_next_free_delivery_date;

        // if we haven't got an initial date, and we need to get the first free one...
        if (defaultToFree?.[initialAddress.country.id]) {
          const nextFreeDate = DateUtilsService.getNearestFreeAvailableTo(datesWithShipping, dateToUse);
          if (nextFreeDate) {
            return Promise.resolve(nextFreeDate);
          }
        }

        // Gets nearest and cheapest available date
        if (this.experimentsService.isActive('HPT78_BW_DE_WEB_DEFAULT_TO_CHEAPEST_DATE', 1)) {
          return DateUtilsService.getNearestAndCheapestAvailableTo(datesWithShipping, dateToUse);
        }

        // otherwise, get the next available
        return DateUtilsService.getNearestAvailableTo(datesWithShipping, dateToUse);
      });
  }

  /**
   * Check if current date is before peak cut off date
   * @param {DeliveryDate[]} datesWithShipping
   * @param {dayjs.Dayjs} deliverableFrom
   */
  freeDateIsBeforePeakCutOff(datesWithShipping: DeliveryDate[], deliverableFrom: dayjs.Dayjs): void {
    const nextFreeDate = DateUtilsService.getNearestFreeAvailableTo(datesWithShipping, deliverableFrom);
    this.peakFreeShipping = nextFreeDate?.date.isBefore(this.peakShippingService.peakShippingCutoffDate) ?? false;
  }

  /**
   * Get next available date with free shipping
   * @param {dayjs.Dayjs} start
   * @param {dayjs.Dayjs} end
   * @param {Product} product
   * @param {Address} address
   * @returns {Promise<DeliveryDate>}
   */
  getNextDateWithFreeShipping(start: dayjs.Dayjs, end: dayjs.Dayjs, product: Product, address: Address): Promise<DeliveryDate> {
    return this.deliveryDateModel
      .getDates(address, product, start, end)
      .then(
        (dates): DeliveryDate =>
          dates.find((deliveryDate): boolean => !!deliveryDate.shippingOptions.find((so): boolean => so.price.price === 0))
      );
  }

  /**
   * Get standard dates for an address and product
   * @param {Address} address
   * @param {Product} product
   * @param {dayjs.Dayjs} start
   * @param {dayjs.Dayjs} end
   * @param {Delivery} delivery
   * @returns {Promise<DeliveryDate[]>}
   */
  getStandardDates(address: Address, product: Product, start: dayjs.Dayjs, end: dayjs.Dayjs, delivery?: Delivery): Promise<DeliveryDate[]> {
    // Protection against old addresses set with no country id
    if (!address.country) {
      return Promise.reject();
    }

    return this.deliveryDateModel.getDates(address, product, start, end, delivery);
  }

  /**
   * Get the dates given an address and sku id
   * @param {Address} address
   * @param {Product} product
   * @param {dayjs.Dayjs} serverTime
   * @param {dayjs.Dayjs} start
   * @param {dayjs.Dayjs} end
   * @returns {Promise<DeliveryDate[]>}
   */
  getDates(address: Address, product: Product, serverTime: dayjs.Dayjs, start?: dayjs.Dayjs, end?: dayjs.Dayjs): Promise<DeliveryDate[]> {
    const startDate = start ?? serverTime.clone();
    const endDate = end ?? startDate.clone().add(startDate.daysInMonth(), 'day');

    return this.getStandardDates(address, product, startDate, endDate).then((results): DeliveryDate[] => {
      const dates = results;

      // concat express dates, then only shipping options after cut off, then only dates which options
      return dates
        .map((d): DeliveryDate => {
          d.shippingOptions = d.shippingOptions
            .filter((o): boolean => o.cutoff.isAfter(serverTime))
            .sort((a, b): number => a.price.price - b.price.price);
          if (this.experimentsService.isActive('HPT106_HIDING_DPD_CLASSIC', 1)) {
            d.shippingOptions = d.shippingOptions.filter((o): boolean => o.id !== 9742);
          }
          return d;
        })
        .sort((a, b): number => a.date.unix() - b.date.unix());
    });
  }

  /**
   * Get the dates given an address and sku id
   * @param {Address} address
   * @param {Product} product
   * @param {ShippingOption} shippingOption
   * @param {dayjs.Dayjs} serverTime
   * @param {dayjs.Dayjs} start
   * @param {dayjs.Dayjs} end
   * @returns {Promise<DeliveryDate[]>}
   */
  getDatesByOrder(
    address: Address,
    product: Product,
    shippingOption: ShippingOption,
    serverTime: dayjs.Dayjs,
    start?: dayjs.Dayjs,
    end?: dayjs.Dayjs
  ): Promise<DeliveryDate[]> {
    const startDate = start ?? serverTime.clone();
    const endDate = end ?? startDate.clone().add(startDate.daysInMonth(), 'day');

    return this.deliveryDateModel.getDatesByOrder(address, product, shippingOption, startDate, endDate).then((results): DeliveryDate[] => {
      const dates = results;

      // concat express dates, then only shipping options after cut off, then only dates which options
      return dates
        .map((d): DeliveryDate => {
          d.shippingOptions = d.shippingOptions.filter((o): boolean => o.cutoff.isAfter(serverTime));
          return d;
        })
        .sort((a, b): number => a.date.unix() - b.date.unix());
    });
  }

  /**
   * Get the dates given an address and sku id
   */
  getDatesByDelivery(
    address: Address,
    product: Product,
    delivery: Delivery,
    serverTime: dayjs.Dayjs,
    start?: dayjs.Dayjs,
    end?: dayjs.Dayjs
  ): Promise<DeliveryDate[]> {
    const startDate = start ?? serverTime.clone();
    const endDate = end ?? startDate.clone().add(startDate.daysInMonth(), 'day');

    return this.getStandardDates(address, product, startDate, endDate, delivery).then((result): DeliveryDate[] => {
      const dates = result;
      // concat express dates, then only shipping options after cut off, then only dates which options
      return dates
        .map((d): DeliveryDate => {
          d.shippingOptions = d.shippingOptions.filter((o): boolean => o.cutoff.isAfter(serverTime));
          return d;
        })
        .sort((a, b): number => a.date.unix() - b.date.unix());
    });
  }

  /**
   * @description Filter out dates that don't have any shipping options
   * @param {DeliveryDate[]} dates
   * @param {dayjs.Dayjs} serverTime
   * @returns {DeliveryDate[]}
   */
  private filterForAvailableDates(dates: DeliveryDate[]): DeliveryDate[] {
    const serverTime = this.configService.getConfig().serverTime;

    return dates
      .map((d): DeliveryDate => {
        // Filter out shipping options that are cut off
        d.shippingOptions = d.shippingOptions?.filter((o): boolean => o.cutoff.isAfter(serverTime));
        return d;
      })
      .filter((d): boolean => d.shippingOptions?.length > 0);
  }
}
