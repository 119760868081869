/* eslint-disable */
export const analyticsKeys = {
  "something.to.go.here": {
    "event": "click",
    "category": "checkout:main",
    "label": "Something-${data.something.here}"
  },
  "button.tracking.here": {
    "event": "click",
    "category": "styleguide",
    "label": "Something"
  },
  "something.to.go.here2": {
    "event": "click",
    "category": "checkout:main",
    "label": "Something"
  },
  "text.replace.example": {
    "event": "click",
    "category": "checkout:main",
    "label": "${data.text}"
  },
  "routing.404": {
    "event": "web:ui:error",
    "category": "fourohfour",
    "label": "${data.path}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "general.app.loaded": {
    "event": "web:app:loaded",
    "platforms": [
      "gtm"
    ]
  },
  "general.shipping.country": {
    "event": "web:ui:shipCountry",
    "category": "defaultCountry",
    "label": "countryId${data.countryId}",
    "platforms": [
      "heap"
    ]
  },
  "component.occasionBanner.action.add.success": {
    "event": "web:ui:click",
    "category": "occasionBanner:addSuccess",
    "label": "occasionBannerAddSuccess",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.occasionBanner.action.add.cancel": {
    "event": "web:ui:click",
    "category": "occasionBanner:addCancel",
    "label": "occasionBannerAddCancel",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.bestSellers.action.select": {
    "event": "web:ui:click",
    "category": "bestSellers:select",
    "label": "bestSellersSelect${data.index}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.appDownload.action.download": {
    "event": "web:ui:click",
    "category": "appDownload:download",
    "label": "appDownloadDownload",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.express-upsell.shown": {
    "event": "web:ui:upsell",
    "category": "deliveryDetails:expressupsell",
    "label": "shown",
    "platforms": [
      "gtm"
    ]
  },
  "component.express-upsell.clicked": {
    "event": "web:ui:click",
    "category": "deliveryDetails:expressupsell",
    "label": "clicked",
    "platforms": [
      "gtm",
      "hotjar"
    ]
  },
  "component.express-upsell.buttonshown": {
    "event": "web:ui:upsell",
    "category": "deliveryDetails:expressupsell",
    "label": "hasshown",
    "platforms": [
      "hotjar"
    ]
  },
  "component.modal.show": {
    "event": "web:ui:modal",
    "category": "${data.modalType}",
    "label": "${data.modalValue}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.modal.close": {
    "event": "web:ui:modal:closed",
    "category": "${data.modalType}",
    "label": "${data.modalValue}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.country-modal.open": {
    "event": "web:ui:click",
    "category": "${data.modalType}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.country-modal.close": {
    "event": "web:ui:modal:closed",
    "category": "${data.modalType}",
    "platforms": [
      "gtm"
    ]
  },
  "component.country-modal.checkout-open": {
    "event": "web:ui:click",
    "category": "ChangeDeliveryCountry",
    "label": "${data.modalValue}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.country-modal.checkout-error": {
    "event": "web:ui:error",
    "category": "addressPostcodeError",
    "label": "${data.modalValue}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.tooltip.show": {
    "event": "web:ui:tooltip",
    "category": "${data.id}",
    "label": "${data.id}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.nav.account": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "account",
    "platforms": [
      "heap"
    ]
  },
  "component.nav.basket": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "basket",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.nav.side": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "side",
    "platforms": [
      "heap"
    ]
  },
  "component.nav.more": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "showmore",
    "platforms": [
      "heap"
    ]
  },
  "component.nav.favourite": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "favourites",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.toaster.close": {
    "event": "web:ui:click",
    "category": "closeErrorMessage",
    "label": "${data.label}",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "checkout.carousel navigation.movedLeft": {
    "event": "web:ui:carouselview",
    "category": "carousel:navigation",
    "label": "movedLeft",
    "platforms": [
      "heap"
    ]
  },
  "checkout.carousel.navigation.movedRight": {
    "event": "web:ui:carouselview",
    "category": "carousel:navigation",
    "label": "movedRight",
    "platforms": [
      "heap"
    ]
  },
  "checkout.carousel.carouselProduct.carouselSend": {
    "event": "web:ui:click",
    "category": "carousel:carouselProduct",
    "label": "carouselSend",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.carousel.carouselProduct.changeProduct": {
    "event": "web:ui:click",
    "category": "carousel:carouselProduct",
    "label": "changeProduct",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.carousel.carouselProduct.deselectProduct": {
    "event": "web:ui:click",
    "category": "carousel:carouselProduct",
    "label": "deselectProduct",
    "platforms": [
      "hotjar"
    ]
  },
  "checkout.grid.filters-hide": {
    "event": "web:ui:click",
    "category": "grid",
    "label": "hideFilters",
    "platforms": [
      "hotjar",
      "gtm",
      "heap"
    ]
  },
  "checkout.grid.filters-show": {
    "event": "web:ui:click",
    "category": "grid",
    "label": "showFilters",
    "platforms": [
      "hotjar",
      "gtm",
      "heap"
    ]
  },
  "checkout.deliveryDetails.continueToUserDetails": {
    "event": "web:ui:click",
    "category": "deliveryDetails:userDetails",
    "label": "continueToUserDetails",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.deliveryDetails.peakShipping.otherSkusFree": {
    "event": "web:ui:click",
    "category": "deliveryDetails:userDetails:peakShipping",
    "label": "otherSkusFree",
    "platforms": [
      "heap"
    ]
  },
  "checkout.deliveryDetails.phoneWasAdded": {
    "event": "web:ui:event",
    "category": "deliveryDetails:userDetails",
    "label": "phoneWasAdded",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.giftingOptions.seeAllCarousel": {
    "event": "web:ui:event",
    "category": "checkout.giftingOptions",
    "label": "seeAllCarousel",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.giftingOptions.seeAllLink": {
    "event": "web:ui:event",
    "category": "checkout.giftingOptions",
    "label": "seeAllLink",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.userDetails.continueToOrderDetails": {
    "event": "web:ui:click",
    "category": "userDetails:orderDetails",
    "label": "continueToOrderDetails",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.userDetails.openLoginModal": {
    "event": "web:ui:click",
    "category": "orderDetails:userDetails",
    "label": "openLoginModal",
    "platforms": [
      "heap"
    ]
  },
  "checkout.userDetails.openCreateAccountModal": {
    "event": "web:ui:click",
    "category": "orderDetails:userDetails",
    "label": "openCreateAccountModal",
    "platforms": [
      "heap"
    ]
  },
  "checkout.userDetails.openGuestsFields": {
    "event": "web:ui:click",
    "category": "orderDetails:userDetails",
    "label": "openGuestsFields",
    "platforms": [
      "heap"
    ]
  },
  "checkout.userDetails.login": {
    "event": "web:ui:click",
    "category": "orderDetails:userDetails",
    "label": "login",
    "platforms": [
      "heap"
    ]
  },
  "checkout.userDetails.createAccount": {
    "event": "web:ui:click",
    "category": "orderDetails:userDetails",
    "label": "createAccount",
    "platforms": [
      "heap"
    ]
  },
  "checkout.orderDetails.sendMoreFlowers": {
    "event": "web:ui:click",
    "category": "orderDetails:sendMoreFlowers",
    "label": "sendMoreFlowers",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.orderDetails.continueToPayment": {
    "event": "web:ui:click",
    "category": "orderDetails:payment",
    "label": "continueToPayment",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.payment.review.review": {
    "event": "web:ui:click",
    "category": "payment:reviewBasket",
    "label": "reviewYourOrder",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.review.remove": {
    "event": "web:ui:click",
    "category": "payment:reviewBasket",
    "label": "removeProduct",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.review.edit": {
    "event": "web:ui:click",
    "category": "payment:reviewBasket",
    "label": "editItem",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.review.sendMoreFlowers": {
    "event": "web:ui:click",
    "category": "payment:review",
    "label": "sendMoreFlowers",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.payment.edit.save": {
    "event": "web:ui:click",
    "category": "checkout:edit",
    "label": "saveChanges",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.edit.cancel": {
    "event": "web:ui:click",
    "category": "checkout:edit",
    "label": "cancelChanges",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-card": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "card",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-saved-card": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "savedCard",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-paypal": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "paypal",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-saved-paypal": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "savedPaypal",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-ideal": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "ideal",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-bancontact": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "bancontact",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-sofort": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "sofort",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-apple-pay": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "applePay",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-google-pay": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "googlePay",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.options.selected-sepa": {
    "event": "web:ui:click",
    "category": "payment:options:selected",
    "label": "sepa",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.pay.sendMoreFlowers": {
    "event": "web:ui:click",
    "category": "payment:pay",
    "label": "sendMoreFlowers",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.payment.pay.payNowCard": {
    "event": "web:ui:click",
    "category": "payment:pay",
    "label": "payNowCard",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.payment.pay.payNowPaypal": {
    "event": "web:ui:click",
    "category": "payment:pay",
    "label": "payNowPaypal",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.payment.discount.tvCodeApplied": {
    "event": "web:ui:click",
    "category": "payment:discount",
    "label": "discountCodeAdded",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.credit.success": {
    "event": "web:ui:click",
    "category": "payment:credit",
    "label": "appliedSuccessfully",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.payment.credit.fail": {
    "event": "web:ui:click",
    "category": "payment:credit",
    "label": "appliedUnsuccessfully",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.payment.discount.error": {
    "event": "web:ui:event",
    "category": "payment:discount:invalid",
    "label": "${data.discountCode}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.discount.removed": {
    "event": "web:ui:click",
    "category": "payment:discount:removed",
    "label": "${data.discountCode}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.discount.applied": {
    "event": "web:ui:click",
    "category": "payment:discount:applied",
    "label": "${data.discountCode}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.discount.alternative-applied": {
    "event": "web:ui:event",
    "category": "payment:discount:alternative",
    "label": "${data.discountCode}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.payment.discount.push-applied": {
    "event": "web:ui:click",
    "category": "payment:discount:push",
    "label": "applied",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.confirmation.banners.shopAgainSave": {
    "event": "web:ui:click",
    "category": "confirmation:banners",
    "label": "shopAgainSave",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.confirmation.banners.mentionMe": {
    "event": "web:ui:click",
    "category": "confirmation:banners",
    "label": "mentionMe",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.app-store-link.confirmation-mobile-banner": {
    "event": "web:ui:click",
    "category": "confirmation:banners",
    "label": "appStore",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.app-store-link.confirmation-desktop-order": {
    "event": "web:ui:click",
    "category": "confirmation:banners",
    "label": "appStore",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.confirmation.action.edit": {
    "event": "web:ui:click",
    "category": "confirmation:action",
    "label": "editOrder",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.action.edit.text": {
    "event": "web:ui:click",
    "category": "confirmation:action",
    "label": "editOrderText",
    "platforms": [
      "heap"
    ]
  },
  "checkout.confirmation.action.vat": {
    "event": "web:ui:click",
    "category": "confirmation:action",
    "label": "downloadVat",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.action.addOccasion.success": {
    "event": "web:ui:modal",
    "category": "confirmation:action",
    "label": "addOccasionSuccess",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.action.addOccasion.cancel": {
    "event": "web:ui:modal",
    "category": "confirmation:action",
    "label": "addOccasionCancel",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.register.addOccasion": {
    "event": "web:ui:event",
    "category": "confirmation:register",
    "label": "addOccasionRegister",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.register.downloadInvoice": {
    "event": "web:ui:event",
    "category": "confirmation:register",
    "label": "downloadInvoiceRegister",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.register.editOrder": {
    "event": "web:ui:event",
    "category": "confirmation:register",
    "label": "editOrderRegister",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.login.addOccasion": {
    "event": "web:ui:event",
    "category": "confirmation:login",
    "label": "addOccasionLogin",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.login.downloadInvoice": {
    "event": "web:ui:event",
    "category": "confirmation:login",
    "label": "downloadInvoiceLogin",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.login.editOrder": {
    "event": "web:ui:event",
    "category": "confirmation:login",
    "label": "editOrderLogin",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.confirmation.statusBar.shopAgainSave": {
    "event": "web:ui:click",
    "category": "confirmation:statusBar",
    "label": "shopAgainSaveStatusBar",
    "platforms": [
      "gtm"
    ]
  },
  "component.newsletter-signup.success": {
    "event": "web:ui:click",
    "category": "newsletter",
    "label": "signup",
    "platforms": [
      "gtm",
      "tvsquared"
    ]
  },
  "checkout.confirmation.banners.inviteFriends": {
    "event": "web:ui:click",
    "category": "confirmation:banners",
    "label": "inviteFriends",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "checkout.confirmation.didRepurchase": {
    "event": "web:ui:click",
    "category": "confirmation:previouslyPurchased",
    "label": "purchaseDidContainPreviouslyPurchased",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.consentControl.optInOption": {
    "event": "web:ui:click",
    "category": "userDetails:optInOptions",
    "label": "optInOption${data.option}",
    "platforms": [
      "heap"
    ]
  },
  "checkout.consentControl.optIn": {
    "event": "web:ui:click",
    "category": "userDetails:${data.userStatus}:optIn",
    "label": "userDetailsOptIn",
    "platforms": [
      "gtm"
    ]
  },
  "checkout.consentControl.optOut": {
    "event": "web:ui:click",
    "category": "userDetails:${data.userStatus}:optOut",
    "label": "userDetailsOptOut",
    "platforms": [
      "gtm"
    ]
  },
  "occasions.create": {
    "event": "web:ui:click",
    "category": "occasions",
    "label": "create",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "nav.navItem.clicked": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "${data.text}",
    "platforms": [
      "gtm"
    ]
  },
  "nav.subNavItem.clicked": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "${data.text}",
    "platforms": [
      "gtm"
    ]
  },
  "nav.filters.toggled": {
    "event": "web:ui:click",
    "category": "filters",
    "label": "filtersOpenedOrClosed",
    "platforms": [
      "gtm"
    ]
  },
  "component.newsletter.tv.actioned": {
    "event": "web:ui:event",
    "category": "newslettertv",
    "label": "actioned",
    "platforms": [
      "gtm",
      "tvsquared"
    ]
  },
  "checkout.emails.optOut": {
    "event": "web:ui:event",
    "category": "newsletter",
    "label": "didOptOut",
    "platforms": [
      "gtm"
    ]
  },
  "newsletter.standard.signup": {
    "event": "web:ui:click",
    "category": "newsletter",
    "label": "emailMethodClickedSignUp",
    "platforms": [
      "gtm"
    ]
  },
  "newsletter.cta.signup": {
    "event": "web:ui:click",
    "category": "newsletter",
    "label": "ctaMethodClickedSignUp",
    "platforms": [
      "gtm"
    ]
  },
  "header.credit.banner": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "creditBannerCta",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "header.credit.apply": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "creditNavApply",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "checkout.discount.iam-fail": {
    "event": "web:ui:click",
    "category": "payment:discount",
    "label": "iamMessageDidNotShow",
    "platforms": [
      "gtm"
    ]
  },
  "nav.filter-education.closed": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "filterEducationWasClosed",
    "platforms": [
      "gtm"
    ]
  },
  "nav.filters.toggilteled": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "filtersToggled",
    "platforms": [
      "heap"
    ]
  },
  "rewards.shopCta.claimFlowers": {
    "event": "web:ui:click",
    "category": "rewards",
    "label": "didClickShopNow-claimFlowers",
    "platforms": [
      "heap"
    ]
  },
  "rewards.shopCta.shopNow": {
    "event": "web:ui:click",
    "category": "rewards",
    "label": "didClickShopNow-shopNow",
    "platforms": [
      "heap"
    ]
  },
  "rewards.account-menu.cta": {
    "event": "web:ui:click",
    "category": "rewards",
    "label": "myRewards",
    "platforms": [
      "heap"
    ]
  },
  "component.favourites.send": {
    "event": "web:ui:click",
    "category": "favourites",
    "label": "send",
    "platforms": [
      "heap"
    ]
  },
  "component.favourites.remove": {
    "event": "web:ui:click",
    "category": "favourites",
    "label": "remove",
    "platforms": [
      "heap"
    ]
  },
  "component.favourites.selected": {
    "event": "web:ui:click",
    "category": "favouritesCta",
    "label": "selected",
    "platforms": [
      "gtm"
    ]
  },
  "component.nav.burgerMenu.open": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "opened",
    "platforms": [
      "heap"
    ]
  },
  "component.nav.burgerMenu.close": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "closed",
    "platforms": [
      "heap"
    ]
  },
  "component.mega-nav-mobile-child.see-all-cta": {
    "event": "web:ui:click",
    "category": "nav",
    "label": "see-all",
    "platforms": [
      "heap"
    ]
  },
  "component.confirmation.download-app": {
    "event": "web:ui:click",
    "category": "confirmation:action",
    "label": "download-app",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.subs-promo-card-modal.shop-subscriptions": {
    "event": "web:ui:click",
    "category": "subsPromoCardModal",
    "label": "shopSubscriptions",
    "platforms": [
      "heap"
    ]
  },
  "component.subs-promo-card-modal.shop-ongoing": {
    "event": "web:ui:click",
    "category": "subsPromoCardModal",
    "label": "shopOnging",
    "platforms": [
      "heap"
    ]
  },
  "component.subs-promo-card-modal.shop-pay-now": {
    "event": "web:ui:click",
    "category": "subsPromoCardModal",
    "label": "shopPayNow",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.skipAddons": {
    "event": "web:ui:click",
    "category": "addonsRefresh",
    "label": "skipAddons",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.selectAddon": {
    "event": "web:ui:click",
    "category": "addonsRefresh",
    "label": "selectAddon",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.addonMoreInfo": {
    "event": "web:ui:click",
    "category": "addonsRefresh",
    "label": "addonMoreInfo",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.addonOnAdded": {
    "event": "web:ui:click",
    "category": "addonsRefresh",
    "label": "addonOnAdded",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.selectUpsellAddon": {
    "event": "web:ui:click",
    "category": "addonsRefresh",
    "label": "selectUpsellAddon",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.selectUpsell": {
    "event": "web:ui:click",
    "category": "addonsRefresh",
    "label": "selectUpsell",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addonsModal.addonAdded": {
    "event": "web:ui:click",
    "category": "addonsRefreshModal",
    "label": "addonAdded",
    "platforms": [
      "heap"
    ]
  },
  "productDetails.modal.close": {
    "event": "web:ui:click",
    "category": "productDetailsModal",
    "label": "productDetailsModalClosed",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.removeAddon": {
    "event": "web:ui:click",
    "category": "addonsRefresh",
    "label": "addonRemoved",
    "platforms": [
      "heap"
    ]
  },
  "checkout.addons.continueToDeliveryDetails": {
    "event": "web:ui:click",
    "category": "addons",
    "label": "continueToDeliveryDetails",
    "platforms": [
      "heap"
    ]
  },
  "component.favourites.favMessageImpression.onheader": {
    "event": "web:ui:event",
    "category": "favMessageImpression",
    "label": "shownOnHeader",
    "platforms": [
      "heap"
    ]
  },
  "component.favourites.favMessageImpression.oncarousel": {
    "event": "web:ui:event",
    "category": "favMessageImpression",
    "label": "shownOnCarousel",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.letterbox-image": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "letterboxImageClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.letterbox-cta": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "letterboxCTAClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.handtied-image": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "handtiedImageClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.handtied-cta": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "handtiedCTAClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.plants-image": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "plantsImageClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.plants-cta": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "plantsCTAClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.subscription-image": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "subsImageClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.homepage-range-education.subscription-cta": {
    "event": "web:ui:click",
    "category": "homepageUnderstandEducation",
    "label": "subsCTAClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.delivery-tracking.feedback.submit": {
    "event": "web:ui:click",
    "category": "deliveryTrackingEducation",
    "label": "feedbackCTAClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.about-handtied-education.section-two-cta": {
    "event": "web:ui:click",
    "category": "aboutHandtiedEducation",
    "label": "shopHandtiedCTAClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.about-handtied-education.section-three-cta": {
    "event": "web:ui:click",
    "category": "aboutHandtiedEducation",
    "label": "shopLetterboxCTAClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.about-letterbox-education.section-one-cta": {
    "event": "web:ui:click",
    "category": "aboutLetterboxEducation",
    "label": "shopLetterboxCTASectionOneClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.about-letterbox-education.section-two-cta": {
    "event": "web:ui:click",
    "category": "aboutLetterboxEducation",
    "label": "shopLetterboxCTASectionTwoClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.about-letterbox-education.section-three-cta": {
    "event": "web:ui:click",
    "category": "aboutLetterboxEducation",
    "label": "shopLetterboxCTASectionThreeClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.show-new-order-form.product-page-back-cta": {
    "event": "web:ui:click",
    "category": "productPage",
    "label": "orderFormBackToProductPageCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "components.product-upsell-addons-modal.submit-cta": {
    "event": "web:ui:click",
    "category": "upsellAddon",
    "label": "confirmUpsell",
    "platforms": [
      "heap"
    ]
  },
  "components.product-upsell-addons-modal.cancel-cta": {
    "event": "web:ui:click",
    "category": "upsellAddon",
    "label": "cancelUpsell",
    "platforms": [
      "heap"
    ]
  },
  "checkout.visualNav.item": {
    "event": "web:ui:click",
    "category": "categoryNavItem",
    "label": "selected",
    "platforms": [
      "heap"
    ]
  },
  "component.meganav-mobile-our-full-collection-breadcrumb": {
    "event": "web:ui:click",
    "category": "visual-nav-breadcrumb",
    "label": "our-full-collection",
    "platforms": [
      "heap"
    ]
  },
  "deliveryTrackingModal.delay.runningLate.addCredit": {
    "event": "web:ui:click",
    "category": "deliveryTrackingModal:delay.runningLate",
    "label": "addCredit",
    "platforms": [
      "heap"
    ]
  },
  "component.order.list.view": {
    "event": "web:ui:click",
    "category": "order-list:view.order",
    "label": "viewOrder",
    "platforms": [
      "heap"
    ]
  },
  "component.order.list.edit": {
    "event": "web:ui:click",
    "category": "order-list:edit.order",
    "label": "editOrder",
    "platforms": [
      "heap"
    ]
  },
  "component.order.list.check": {
    "event": "web:ui:click",
    "category": "order-list:check.order",
    "label": "checkOrder",
    "platforms": [
      "heap"
    ]
  },
  "component.order.list.track-delivery": {
    "event": "web:ui:click",
    "category": "order-list:track.delivery",
    "label": "trackDelivery",
    "platforms": [
      "heap"
    ]
  },
  "component.help-center.track-delivery": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "startModal",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.contact-us": {
    "event": "web:ui:click",
    "category": "help-center:contactus",
    "label": "contactus",
    "platforms": [
      "heap"
    ]
  },
  "component.help-center.notify-arrange-resend": {
    "event": "web:ui:click",
    "category": "helpCentreModal:arrangeResend",
    "label": "Opt in for delivery updates",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.track-other-delivery-credit": {
    "event": "web:ui:click",
    "category": "helpCentreModal:creditApplied",
    "label": "Track another delivery",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.track-other-delivery-resend": {
    "event": "web:ui:click",
    "category": "helpCentreModal:resendConfirmation",
    "label": "Track another delivery",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.contact-us-eligible-orders": {
    "event": "web:ui:click",
    "category": "helpCentreModal:eligibleOrders",
    "label": "Contact Customer Delight",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.contact-us-order-tracked": {
    "event": "web:ui:click",
    "category": "helpCentreModal:orderTracking",
    "label": "Contact Customer Delight",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.contact-us-credit": {
    "event": "web:ui:click",
    "category": "helpCentreModal:creditApplied",
    "label": "Contact Customer Delight",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.contact-us-resend": {
    "event": "web:ui:click",
    "category": "helpCentreModal:resendConfirmation",
    "label": "Contact Customer Delight",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.send-replacement": {
    "event": "web:ui:click",
    "category": "helpCentreModal:arrangeResend",
    "label": "Send replacement",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.arrange-resend": {
    "event": "web:ui:click",
    "category": "helpCentreModal:orderTracking",
    "label": "Arrange a resend",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.credit-account": {
    "event": "web:ui:click",
    "category": "helpCentreModal:orderTracking",
    "label": "Credit my account",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.track-my-delivery": {
    "event": "web:ui:click",
    "category": "helpCentreModal:eligibleOrders",
    "label": "Track my delivery",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.track-another-delivery": {
    "event": "web:ui:click",
    "category": "helpCentreModal:orderTracking",
    "label": "Track my delivery",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.next-delivery": {
    "event": "web:ui:click",
    "category": "helpCentreModal:eligibleOrders",
    "label": "Next",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.latest-delivery": {
    "event": "web:ui:click",
    "category": "helpCentreModal:eligibleOrders",
    "label": "latest Order",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.start-model-load": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "startModal",
    "platforms": [
      "gtm"
    ]
  },
  "component.help-center.eligible-orders-load": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "eligibleOrders",
    "platforms": [
      "gtm"
    ]
  },
  "component.help-center.order-tracking-load": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "orderTracking - ${data.deliverytTrackingState}",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.credit-applied-load": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "creditApplied - ${data.deliverytTrackingState}",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.arrange-resend-load": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "arrangeResend - ${data.deliverytTrackingState}",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.resend-confirm-load": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "resendConfirmation - ${data.deliverytTrackingState}",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.not-found": {
    "event": "web:ui:click",
    "category": "helpCentreModal",
    "label": "Something went wrong - ${data.error}",
    "platforms": [
      "heap",
      "gtm"
    ]
  },
  "component.help-center.certificate": {
    "event": "web:ui:click",
    "category": "helpCentreModal:orderTracking",
    "label": "Download e-certificate",
    "platforms": [
      "gtm"
    ]
  },
  "component.gift-card.actions.save.close.modal": {
    "event": "web:ui:click",
    "category": "gift-card",
    "label": "Save Gift Card",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.modal.cover.select": {
    "event": "web:ui:click",
    "category": "${data.modalType}",
    "label": "${data.modalValue}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "component.i18nmodal.close": {
    "event": "web:ui:modal:closed",
    "category": "${data.modalType}",
    "label": "${data.modalValue}",
    "platforms": [
      "gtm",
      "heap"
    ]
  },
  "contentful.header-block.cta": {
    "event": "web:ui:click",
    "category": "contentfulHeaderBlock",
    "label": "viewMoreCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.story-block.primary-cta": {
    "event": "web:ui:click",
    "category": "contentfulStoryBlock",
    "label": "primaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.story-block.secondary-cta": {
    "event": "web:ui:click",
    "category": "contentfulStoryBlock",
    "label": "secondaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.image-gallery-block.primary-cta": {
    "event": "web:ui:click",
    "category": "contentfulImageGalleryBlock",
    "label": "primaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.image-gallery-block.secondary-cta": {
    "event": "web:ui:click",
    "category": "contentfulImageGalleryBlock",
    "label": "secondaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.hero-block.primary-cta": {
    "event": "web:ui:click",
    "category": "contentfulHeroBlock",
    "label": "primaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.generic-content-block.primary-cta": {
    "event": "web:ui:click",
    "category": "genericContentBlock",
    "label": "primaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.generic-content-block.secondary-cta": {
    "event": "web:ui:click",
    "category": "genericContentBlock",
    "label": "secondaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.generic-content-item-block.secondary-cta": {
    "event": "web:ui:click",
    "category": "genericContentItemBlock",
    "label": "secondaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "contentful.hero-block.secondary-cta": {
    "event": "web:ui:click",
    "category": "contentfulHeroBlock",
    "label": "secondaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "grid.modular-product-card-cta": {
    "event": "web:ui:click",
    "category": "ModularContentBlock",
    "label": "${data.value}",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "grid.view-toggle.stack": {
    "event": "web:ui:click",
    "category": "GridViewToggle",
    "label": "StackViewClicked",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "grid.view-toggle.column": {
    "event": "web:ui:click",
    "category": "GridViewToggle",
    "label": "ColumnViewClicked",
    "platforms": [
      "gtm",
      "heap",
      "hotjar"
    ]
  },
  "contentful.subspage.faq.accordion.question": {
    "event": "web:ui:click",
    "category": "${data.category}",
    "label": "${data.label}",
    "platforms": [
      "gtm",
      "heap",
      "ga4"
    ]
  },
  "component.subs-page.start-checkout-cta": {
    "event": "web:ui:click",
    "category": "${data.category}",
    "label": "${data.label}",
    "platforms": [
      "gtm",
      "heap",
      "ga4"
    ]
  },
  "component.story-telling.faq-cta": {
    "event": "web:ui:click",
    "category": "storyTellingComponent",
    "label": "faqCtaClicked",
    "platforms": [
      "gtm",
      "heap",
      "ga4"
    ]
  },
  "component.auth-modal.user-logged-in": {
    "event": "${data.event}",
    "category": "${data.category}",
    "label": "${data.label}",
    "platforms": [
      "gtm"
    ]
  },
  "component.auth-modal.user-signed-up": {
    "event": "${data.event}",
    "category": "${data.category}",
    "label": "${data.label}",
    "platforms": [
      "gtm"
    ]
  },
  "component.subs-grid.start-checkout": {
    "event": "${data.event}",
    "category": "${data.category}",
    "label": "${data.label}",
    "value": "${data.value}",
    "platforms": [
      "gtm",
      "ga4"
    ]
  },
  "component.confirmation.purchase-a-sub": {
    "event": "${data.event}",
    "category": "${data.category}",
    "label": "${data.label}",
    "value": "${data.value}",
    "platforms": [
      "gtm",
      "ga4"
    ]
  },
  "component.payment.purchase-a-sub": {
    "event": "${data.event}",
    "category": "${data.category}",
    "label": "${data.label}",
    "platforms": [
      "gtm",
      "ga4"
    ]
  },
  "oasys.hero-block.primary-cta": {
    "event": "web:ui:click",
    "category": "oasysHeroBlock",
    "label": "primaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "oasys.hero-block.secondary-cta": {
    "event": "web:ui:click",
    "category": "oasysHeroBlock",
    "label": "secondaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "oasys.story-block.primary-cta": {
    "event": "web:ui:click",
    "category": "oasysStoryBlock",
    "label": "primaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "oasys.story-block.secondary-cta": {
    "event": "web:ui:click",
    "category": "oasysStoryBlock",
    "label": "secondaryCtaClicked",
    "platforms": [
      "heap"
    ]
  },
  "oasys.hva-banner.cta": {
    "event": "web:ui:click",
    "category": "oasysHvaBanner",
    "label": "ctaClicked",
    "platforms": [
      "heap"
    ]
  },
  "oasys.category-card.cta": {
    "event": "web:ui:click",
    "category": "oasysCategoryCardCarousel",
    "label": "categoryClicked",
    "platforms": [
      "heap"
    ]
  },
  "oasys.blog-carousel-card.cta": {
    "event": "web:ui:click",
    "category": "oasysBlogCardCarousel",
    "label": "blogCardClicked",
    "platforms": [
      "heap"
    ]
  },
  "oasys.product-carousel-card.cta": {
    "event": "web:ui:click",
    "category": "oasysProductCardCarousel",
    "label": "productCardClicked",
    "platforms": [
      "heap"
    ]
  },
  "component.modular-content-card.cta": {
    "event": "web:ui:click",
    "category": "modularContentCard",
    "label": "ctaClicked",
    "platforms": [
      "heap"
    ]
  },
  "component.discount-code-redemption.apply-cta": {
    "event": "web:ui:click",
    "category": "payment:discount:applied",
    "label": "${data.label}",
    "platforms": [
      "heap"
    ]
  },
  "component.discount-code-redemption.cancel-cta": {
    "event": "web:ui:click",
    "category": "payment:discount:cancelled",
    "label": "${data.label}",
    "platforms": [
      "heap"
    ]
  },
  "component.discount-code-redemption.remove-cta": {
    "event": "web:ui:click",
    "category": "payment:discount:removed",
    "label": "${data.label}",
    "platforms": [
      "heap"
    ]
  },
  "component.more-information-cta-on-pdp": {
    "event": "web:ui:click",
    "category": "pdp-cta",
    "label": "More information",
    "platforms": [
      "heap"
    ]
  }
};