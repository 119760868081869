import { Injectable } from '@angular/core';
import { Country } from 'Shared/classes/country';
import { AddonRequirement, Product } from 'Shared/classes/product';
import { UpsellOption } from 'Shared/classes/upsell-option';
import { APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import * as dayjs from 'dayjs';
import { Price } from 'Shared/classes/price';
import { Discount } from 'Shared/classes/discount';
import { DiscountInfo, DiscountType } from 'Shared/classes/discount-info';
import { WindowRefService } from 'Shared/services/window.service';
import { ConfigService } from 'Shared/services/config.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';
import { ApiProductResponse } from 'Shared/classes/delivery';

@Injectable({
  providedIn: 'root'
})
export class ProductModelService {
  constructor(
    private backend: BackendService,
    private windowRefService: WindowRefService,
    private configService: ConfigService,
    private experimentService: ExperimentsService,
    private featureService: FeaturesService
  ) {}

  /**
   * From the backend payload
   * @param res
   */
  static fromPayload(
    res:
      | APISerialisedJSONResponse<'/v2/availability/products'>['data'][number]
      | APISerialisedJSONResponse<'/v2/skus/:productId' | ApiProductResponse>
  ): Product {
    const attr =
      (res as APISerialisedJSONResponse<'/v2/availability/products'>['data'][number]).attributes ||
      (res as APISerialisedJSONResponse<'/v2/skus/:productId'>);
    attr.media = attr.media && attr.media.length ? attr.media : [];

    const product = new Product();
    product.id = parseInt(res.id, 10);
    product.slug = attr.slug;
    product.name = attr.name;
    product.bundleOnly = attr.bundle_only || false;
    product.singleOnly = attr.single_only || false;
    product.subscriptionOnly = attr.subscription_only || false;
    product.lilyFree = attr.lily_free;
    product.over18Only = attr.eighteen_plus;
    product.collectionName = attr.collection_name;
    product.collectionId = attr.collection_id;
    product.isPreorder = attr.is_pre_order;
    product.isInStock = attr.in_stock;
    product.latestShippingOptionCutoff = attr.latest_shipping_option_cut_off ? dayjs(attr.latest_shipping_option_cut_off) : undefined;

    product.description = attr.description;
    product.longDescription = attr.long_description;
    product.shortDescription = attr.email_description;

    product.imageUrls = attr.media.length ? attr.media.map((m) => m.url) : attr.imageUrls || [];
    product.addonPrimaryImage = attr.media.length
      ? attr.media.find((m): boolean => m.tags_web.indexOf('addon-primary-image') > -1)?.url
      : undefined;

    // SKU_IMAGE_SWAP EXPERIMENT
    product.skuImageSwap = [];

    product.videoUrl = attr.video_url;
    product.videoThumbnail = attr.video_thumbnail_url;

    (attr.media || []).forEach((img): number => product.skuImageSwap.push({ url: img.url, tag: img.tags_web }));

    if ((res as APISerialisedJSONResponse<'/v2/skus/:productId'>).bouquet_images) {
      product.imageUrls = (res as APISerialisedJSONResponse<'/v2/skus/:productId'>).bouquet_images
        .filter((r): boolean => r.kind === 'letterbox-main')
        .map((image): string => image.urls.website_carousel.x1);
    }

    product.filterAttributes = attr.sku_attributes;

    product.upsells = (attr.associated_skus || []).map(
      (u): UpsellOption => ({
        id: u.id,
        type: u.kind,
        product: u.associated_sku_id ? new Product(u.associated_sku_id) : undefined,
        toggleText: u.display_text || u.bundle_duration,
        infoHeading: u.display_header,
        infoBody: u.display_body
      })
    );

    /**
     * Sometimes the backend doens't give us the best data
     * So we hide this fact, and show the products regarless of bad data...
     */
    try {
      product.discountAddon = (attr.discount_info || [])
        .map((d) => {
          const discount = new DiscountInfo();
          discount.id = d.id;
          discount.name = d.name;
          discount.slug = d.slug;
          discount.type = d.type;
          discount.price = new Price(attr.currency, 1, d.price[0].price_pennies, { discounted: d.price[0].price_pennies_discounted });
          discount.imageUrls = d.media.map((m) => m.url);
          return discount;
        })
        .find((di) => di.type === DiscountType.Addon);
    } catch (e) {}

    product.appearingFrom = dayjs(attr.appearing_from);
    product.appearingTo = dayjs(attr.appearing_to);
    product.deliverableFrom = dayjs(attr.deliverable_from).startOf('day');
    product.deliverableTo = dayjs(attr.deliverable_to).endOf('day');

    product.rating = {
      count: attr.rating_count,
      average: attr.rating_average
    };
    product.tags = (attr.tags || []).map((t) => t.toLowerCase());
    product.type = attr.product_kind;

    product.addonRequirements = Object.entries(attr.addon_requirements || {}).map(
      ([key, value]: [
        string,
        {
          min: number;
          max: number;
          default_addon_sku_id: number;
        }
      ]) => ({
        kind: key as AddonRequirement['kind'],
        min: value.min,
        max: value.max,
        defaultAddonId: value.default_addon_sku_id
      })
    );

    const giftCardRelatedRequirements = product.getGreetingCardAddonRequirement();

    // Hack - ensure that every sku has an addon requirement for an optional gift card
    // This should be set-up in admin
    if (!giftCardRelatedRequirements) {
      product.addonRequirements.push({
        min: 1,
        max: 1,
        kind: 'gift_card',
        defaultAddonId: undefined
      });
    }

    product.setPricingV2(attr.currency, attr.prices);

    product.setLabels({
      featuredLabelText: attr.label,
      showRewardsLabel: attr.prices && attr.prices[0]?.reward_points_with_bonus > attr.prices[0]?.reward_points
    });

    // Self purchase subscription
    const hasSelfPurchaseSubscriptionTag = (attr.tags || []).indexOf('self-purchase-subscription') > -1;
    if (hasSelfPurchaseSubscriptionTag) {
      product.isSelfPurchaseSubscription = hasSelfPurchaseSubscriptionTag;
    }

    return product;
  }

  /**
   * Using the products API, get a product details, but we don't care for it's price
   * @param product
   * @param country
   */
  getProduct(product: Product, country: Country): Promise<Product> {
    return !product ? Promise.resolve(undefined) : this.getProducts(country).then((products) => products.find((p) => p.id === product.id));
  }

  /**
   * Get an older product - ie one that doesnt exist in the products API
   * @param product
   */
  getOlderProduct(product: Product): Promise<Product> {
    return this.backend
      .get(null, `/v2/skus/${product.id}` as '/v2/skus/:productId', {
        useUrlAsCache: true,
        responseIsJsonApi: true,
        params: {
          include: 'bouquet_images'
        }
      })
      .then((res) => ProductModelService.fromPayload(res));
  }

  /**
   * Get the products, regardless of dates
   * @param country
   * @param orderIndex
   * @param discount
   */
  getProducts(country: Country, orderIndex?: number, discount?: Discount, deliveryDate?: any): Promise<Product[]> {
    const firstItemInPurchase = !orderIndex || orderIndex === 0;
    const discountCode = discount ? discount.code : undefined;

    return this.backend
      .get(null, '/v2/availability/products', {
        useUrlAsCache: true,
        sendExperiments: ['API_'],
        params: {
          shipping_country_id: country.id,
          first_item_in_purchase: firstItemInPurchase,
          discount_code: discountCode,
          delivery_date: deliveryDate,
          enable_gift_vouchers: this.featureService.getFeature('GIFT_VOUCHERS').active
        }
      })
      .then((res) => {
        const products = res && res.data ? res.data.map((r) => ProductModelService.fromPayload(r)) : [];

        // Swap sku images if experiments are running:
        return products ? this.swapSkuImages(products) : [];
      });
  }

  linkProductUpsells(upsells: UpsellOption[], products: Product[]): UpsellOption[] {
    const upsellOptions: UpsellOption[] = [];

    // Upsells can either have a product
    upsells.forEach((u) => {
      const upsellProduct = products.find((p) => u.product && p.id === u.product.id);

      if (upsellProduct) {
        upsellOptions.push({
          id: u.id,
          type: u.type,
          product: upsellProduct,
          toggleText: u.toggleText,
          infoHeading: u.infoHeading,
          infoBody: u.infoBody,
          promoImage: u.promoImage
        });
      }
    });

    return upsellOptions;
  }

  /**
   * Get all products
   * @param country
   */
  getAvailableProducts(country: Country, orderIndex: number, discount?: Discount, deliveryDate?: string): Promise<Product[]> {
    return this.getProducts(country, orderIndex, discount, deliveryDate).then((prods) => {
      const serverTime = this.configService.getConfig().serverTime;

      const products = prods.filter(
        (product) => product.appearingFrom.unix() <= serverTime.unix() && product.appearingTo.unix() >= serverTime.unix()
      );

      return products.map((product) => {
        product.upsells = this.linkProductUpsells(product.upsells, products);
        return product;
      });
    });
  }

  /**
   *
   * @param products
   * @returns
   */
  private swapSkuImages(products: Product[]): Product[] {
    // Clone the products
    let mappedProducts: Product[] = products.slice();

    // Get the config value to find the experiment & tags
    const config = this.configService.getConfig().web_sku_image_swapping;
    const sku_image_swapping_config = config ? config : [];

    // Extract active experiment settings
    sku_image_swapping_config.forEach((experiment) => {
      const activeExperiment = this.experimentService.getExperiment(experiment.experiment);

      // Create experiment & variant check
      const validExperiment =
        activeExperiment && activeExperiment.variant === experiment.variant && activeExperiment.name === experiment.experiment;

      const activeTag = validExperiment ? experiment.tag : undefined;

      // Now that we have exteacted them remove them from the product.imageUrls array to
      // avoid duplication on the Grid both In & Out of the experiment.
      // This is what stops the duplication of images
      mappedProducts = this.setSwappableSkuImageInCorrectPosition(mappedProducts, experiment.tag, false);

      // Check for experiemnt validity
      if (validExperiment) {
        mappedProducts = this.setSwappableSkuImageInCorrectPosition(mappedProducts, activeTag, true);
      }
    });

    return mappedProducts;
  }

  /**
   *
   * @param mappedProducts
   * @param tag
   * @param swapImages
   * @returns
   */
  private setSwappableSkuImageInCorrectPosition(mappedProducts: Product[], tag: string, swapImages: boolean): Product[] {
    mappedProducts.forEach((product) => {
      const mappedSkuSwapImages = [];
      // NOTE: we are using currentTag here because we wanna make sure we only extract images tagged for a specific
      // experiment variant
      product.skuImageSwap.forEach((img) => (img.tag.indexOf(tag) > -1 ? mappedSkuSwapImages.push(img) : undefined));

      // This is where we are actually swapping the main image with the new one.
      mappedSkuSwapImages.forEach((img, index) => {
        if (swapImages) {
          product.imageUrls.splice(index, 1, img.url);
        }

        // If the tagged image is within the imageUrls array remove it
        if (!swapImages && product.imageUrls.indexOf(img.url) > -1) {
          product.imageUrls.splice(product.imageUrls.indexOf(img.url), 1);
        }
      });
    });

    return mappedProducts;
  }
}
