import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { User, UserService } from 'Shared/services/user.service';
import { LocationService } from 'Shared/services/location.service';
import { ContentService } from 'Shared/services/content.service';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { CountryService } from 'Shared/services/country.service';
import { Email } from 'Shared/classes/email';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { EmailService } from 'Shared/services/email.service';
import { ConfigService } from 'Shared/services/config.service';
import { CustomKeysService } from 'Shared/services/custom-keys.service';
import { LazyLoaderService } from 'Shared/services/lazy-loader.service';
import { HeapService } from 'Shared/services/third-parties/heap.service';
import { UntilIdleService } from 'Shared/services/until-idle.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigModelService } from 'Shared/models/config-model.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ReturningCustomerService } from 'Shared/services/returning-customer.service';
import { NewsletterStatusService } from 'Shared/services/newsletter-status.service';
import { Experiment } from 'Shared/classes/experiment';
import { WindowRefService } from 'Shared/services/window.service';
import { ActivatedState } from 'Shared/classes/activated-state';
import { ActivationStart, Params } from '@angular/router';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { GlobalIntersectionService } from 'Shared/services/global-intersection.service';
import { InitialLoadService } from 'Shared/services/initial-load.service';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import 'Shared/types/global';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { DigitalGeniusWidgetService } from 'Shared/services/third-parties/digital-genius-widget.service';
import { FeaturesService } from 'Shared/services/features.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Hello World';
  isStateNotCheckout: boolean;
  isCountry: string;
  hasLaunched: boolean = false;
  user$: BehaviorSubject<User> = this.userService.user$;
  displayNav: boolean;
  initialUrlParams: string;
  displayShop: boolean;
  window: Window;
  ready: boolean = false;
  stateIsCheckout: boolean;
  showFullClosureModal: boolean;
  showZendeskWidget: boolean;

  minHeightTimerReset: ReturnType<typeof setTimeout>;
  hideNav: boolean = false;
  displayZendesk: boolean = false;
  cookieConsent: ICookieConsent;
  private cookieSubscription$: Subscription;
  private cuntrySubscription$: Subscription;

  constructor(
    private userService: UserService,
    private locationService: LocationService,
    private contentService: ContentService,
    private purchaseService: PurchaseService,
    private countryService: CountryService,
    private localStorageService: LocalStorageService,
    private experimentService: ExperimentsService,
    private analyticsService: AnalyticsService,
    private emailService: EmailService,
    private configService: ConfigService,
    private customKeys: CustomKeysService,
    private stateService: StateService,
    private lazyLoaderService: LazyLoaderService,
    private heapService: HeapService,
    private untilIdleService: UntilIdleService,
    private domUtilsService: DomUtilsService,
    private configModelService: ConfigModelService,
    private returningCustomerService: ReturningCustomerService,
    private newsletterStatusService: NewsletterStatusService,
    private windowRef: WindowRefService,
    private initialLoadService: InitialLoadService,
    private cookieService: CookieService,
    private viewPort: ViewportDetectionService,
    private globalIntersectionService: GlobalIntersectionService,
    private optimizelyService: OptimizelyService,
    private digitalGeniusWidgetService: DigitalGeniusWidgetService,
    private featuresService: FeaturesService
  ) {}

  /**
   * Subscribe based on params when the app first loads
   */
  subscribeBasedOnParams(): void {
    const emailSlug = this.stateService.getCurrent().params.emailSlug;
    const emailPreference = this.stateService.getCurrent().params.emailPreference;
    if (emailSlug && emailPreference) {
      const email = new Email('');
      email.slug = emailSlug;
      email.preference = parseInt(emailPreference, 10);
      email.consent = {
        copy: 'Double-Opt in email link',
        method: 'Via ?emailSlug & ?emailPreference',
        location: `Web - ${window.location.hostname}${window.location.pathname}`
      };
      this.emailService.update(email);
    }
  }

  // Handle the event emitted by bw-launch-modal to check whether the modal has been launched
  hasLaunchedModal(event: boolean): void {
    this.hasLaunched = event;
  }

  /**
   * Function to handle the applying of utm values on appload
   * @param  {any} params - Url params
   */
  applyUtmCodeAsDiscount(firstParams: Params): Params {
    if (
      firstParams.utm_source !== 'product-feed' ||
      (firstParams.utm_medium !== 'facebook' && firstParams.utm_medium !== 'google') ||
      !firstParams.utm_content
    ) {
      return firstParams;
    }

    if (firstParams.utm_campaign === 'discount') {
      return Object.assign(firstParams, { discountCode: firstParams.utm_content });
    }
    if (firstParams.utm_campaign === 'tracking') {
      return Object.assign(firstParams, { trackingCode: firstParams.utm_content });
    }

    return firstParams;
  }

  setCDExperiments(cdExperiments: string[]): void {
    if (!cdExperiments) {
      return;
    }
    cdExperiments.forEach((e): void => {
      const experiment = new Experiment();
      experiment.name = e.split(':')[0];
      experiment.variant = parseInt(e.split(':')[1], 10);
      experiment.allowOverride = false;
      this.experimentService.addExperiment(experiment);
    });
  }
  /* eslint-disable complexity */
  ngOnInit(): Promise<void> {
    const firstUrl = this.locationService.path();
    const firstParams = this.locationService.getUrlParams();

    // Initialising our viewport detection service
    this.viewPort.initViewportCheck();

    // check for already stored consent, initalise necessary services
    this.cookieConsent = this.cookieService.init({
      userSlug: this.userService.getUserSlug(),
      fingerprint: this.userService.getFingerprint()
    });

    // Intialise Optimizely & set the User Context if config is set and Optimizely init is available
    if (this.configService.getConfig().optimizelyEnabled && this.optimizelyService.init) {
      this.optimizelyService.init();
    }

    const urlParams = this.applyUtmCodeAsDiscount(firstParams);
    const firstState = this.stateService.createRoute('initial', firstUrl, urlParams);
    this.stateService.setInitial(firstState);
    this.initialUrlParams = window.location.search;
    this.isCountry = this.configService.getConfig().site;

    this.returningCustomerService.recordFirstVisit();
    this.newsletterStatusService.init();

    const initialUrl = `${window.location.pathname}${window.location.search}`;

    this.setDimensions(this.configService.getConfig().locale, this.countryService.forShipping.id);
    this.analyticsService.setCurrency(this.countryService.forShipping.currencyCode);

    this.stateService.onAppLoad$.subscribe((tranistion): void => {
      // Check if the country Id is supported
      const countryId = (((tranistion || {}).to || {}).params || {}).countryId;

      // Temporary redirect to remove when we'll remove the FR site from the codebase
      // TODO: remove as part of #180759792
      if (countryId === '5') {
        this.windowRef.nativeWindow.location = 'site-closed-fr-en.html';
      }

      // Hack - to remove as part of #181217232
      const urlLocaleParam = this.stateService.getInitial().params?.locale || '';
      this.hideNav = urlLocaleParam === 'fr';

      if (countryId && countryId !== this.countryService.forShipping.id && !this.countryService.supportsShippingToCountryId(countryId)) {
        this.windowRef.nativeWindow.location = `${this.locationService.convertCountryToSubfolder(initialUrl)}`;
      }

      const to = tranistion.to && tranistion.to.name ? tranistion.to.name : undefined;
      // To check whether we directly landed on the carousel
      this.heapService.reachedCarouselFrom(undefined, to);

      // Just in case we have any outstanding things to do
      this.untilIdleService.runAll();

      this.stateService.setInitial(tranistion.to);
    });

    this.stateService.onAppClose$.subscribe((): void => {
      if (this.configService.getConfig().heapEnabled) {
        this.heapService.clearEventProperties();
      }
    });
    // We need to wait till the config service has a remote config, then we can assign the experiments correctly
    this.configModelService.hasRemoteConfig().then((config): void => {
      const currentCountry = this.countryService.forSite;
      let perCountryConfig = this.configService.forCountry(currentCountry);
      let rollOutByCountry = perCountryConfig.experimentsRolledOut || {};
      this.experimentService.setRolledOutExperiments(rollOutByCountry);

      if (this.configService.getConfig().optimizelyEnabled && this.optimizelyService.init) {
        this.optimizelyService.decide('sku_price_test');
        this.optimizelyService.decide('navigation_test');

        // Test gifting rebuild and shipping price test
        this.optimizelyService.decide('api_hpt108_paid_shipping_and_gc_rebuild');
        this.optimizelyService.decide('api_hpt109_paid_shipping_and_gc_rebuild');

        // Test Optimizely event for prod based testing - to be removed after successful testing
        this.optimizelyService.decide('bw_uk_web_staging_cookies_order_id');

        this.optimizelyService.decide('discount_error_change');

        this.optimizelyService.decide('auto_opt_in_to_rewards');

        // Optimizely decide for order total sticky footer experiment
        this.optimizelyService.decide('pricing_clarity_slice_1');

        // Optimizely decide for Stripe Payment Element AB test
        this.optimizelyService.decide('stripe_payment_element');
      }

      // Full closure modal config
      const showClosureConfig = config.show_closure_modal_v2 || {};
      // If user changes shipping country reset hasLaunched Value
      this.cuntrySubscription$ = this.countryService.forShipping$.subscribe((country): void => {
        this.hasLaunched = false;
        // Check what the config says for the specific country
        this.showFullClosureModal = showClosureConfig[country.id];
        this.setDimensions(this.configService.getConfig().locale, country.id);

        this.heapService.setGlobalEventProperty({ shippingCountryId: country.id });

        // Change what rolled out we are in per site if needed
        perCountryConfig = this.configService.forCountry(country);
        rollOutByCountry = perCountryConfig['experimentsRolledOut'] || {};
        this.experimentService.setRolledOutExperiments(rollOutByCountry);
      });
    });

    /**
     * On transition, track the page too
     */
    this.stateService.onBefore$.subscribe((transition): void => {
      // Ensure that the min height for the maintain scroll point is cleared
      clearTimeout(this.minHeightTimerReset);
      document.body.style.minHeight = null;

      const from = transition.from && transition.from.name ? transition.from.name : undefined;
      const to = transition.to && transition.to.name ? transition.to.name : undefined;

      this.heapService.reachedCarouselFrom(from, to);
      // Just in case we have any outstanding things to do
      this.untilIdleService.runAll();

      // Setting the country before the transition, so we can reload the carousel/navigation when required
      const countryId = transition.to.params.countryId;
      if (countryId && countryId !== this.countryService.forShipping.id) {
        const country = this.countryService.getCountryBy('id', parseInt(countryId, 10));
        this.analyticsService.trackCountryChange(this.countryService.forShipping, country);
        this.countryService.setCountryForShipping(country);
      }

      const url = this.stateService.getUrlForState(transition.to.name, transition.to.params);
      this.contentService.get(url).catch((): void => {}); // Stops errors being reported to bugsnag

      if (this.returningCustomerService.isReturning && transition.to.name === 'homepage') {
        this.experimentService.fireEvent('returningUserVisitedHomepage');
      }
    });

    /**
     * The end of the Resolve phase of routing,
     * before Angular router instantiates the components
     */
    this.stateService.onResolveEnd$.subscribe((transition: { to: ActivatedState; from?: ActivationStart }): void => {
      // We want to ensure optimise is first - in case this changes the styles
      let url = this.stateService.getUrlForState(transition.to.name, transition.to.params);
      if (!url) {
        url = transition.to.url;
      }
      const appSubfolder = this.locationService.appUrlSubfolder || '/';
      const withSubfolder = `${appSubfolder}${url}`.replace('//', '/'); // Replace any double slashes here
      this.analyticsService.optimizeActivate(withSubfolder);
    });

    /**
     * On success
     */
    this.stateService.onSuccess$.subscribe((transition: { to: ActivatedState; from?: ActivationStart }): void => {
      const toState = transition.to;

      const url = this.stateService.getUrlForState(transition.to.name, transition.to.params);
      const appSubfolder = this.locationService.appUrlSubfolder || '/';
      const withSubfolder = `${appSubfolder}${url}`.replace('//', '/'); // Replace any double slashes here

      this.analyticsService.trackPage(withSubfolder);
      // We pass these values into the nav component to hide it on payment page.
      if (
        toState.name.indexOf('checkout.payment') === 0 ||
        toState.name.indexOf('checkout.edit') === 0 ||
        toState.name.indexOf('checkout.giftOptions') === 0 ||
        toState.name.indexOf('checkout.greetingCardsGrid') === 0
      ) {
        this.displayNav = false;
        this.displayShop = false;
      } else {
        this.displayNav = true;
        this.displayShop = true;
      }
      // This will make the param 'stick', and useful for when params are in the url and not querystrings
      const params = this.stateService.getInitial().params;

      // Do not show the zendesk widget for CD purchases
      this.showZendeskWidget = !params.source || (params.source && params.source !== 'phone');

      const purchase = this.purchaseService.getPurchase();
      if (params.source) {
        purchase.source = params.source;
        this.purchaseService.setAsCurrent(purchase);
      }

      this.stateIsCheckout = toState.name.indexOf('checkout') > -1;

      // checking if we are not in the 'checkout' state or if the checkout state is 'confirmation' then we want to show the modal
      this.isStateNotCheckout = toState.name.indexOf('checkout') === -1 || toState.name.indexOf('checkout.confirmation') === 0;
      document.body.setAttribute('ui-state', toState.name);
      document.body.setAttribute('url', window.location.pathname);

      // Hacky little fix for URLs that have been encoded....
      if (window.location.href.indexOf('%2F') > -1) {
        const newUrl = window.location.href.replace(/%2F/g, '/');
        history.replaceState(null, null, newUrl);
      }

      if (toState.data.scrollYPosition) {
        // In case the user was at the top of the page, just add to window height too
        document.body.style.minHeight = `${toState.data.scrollYPosition + window.outerHeight}px`;

        setTimeout((): void => {
          window.scroll({ top: toState.data.scrollYPosition, left: 0, behavior: 'auto' });
        }, 1);

        // Wait a second or two to reset, hopefully enough time for the whole page to load
        this.minHeightTimerReset = setTimeout((): void => {
          document.body.style.minHeight = null;
        }, 5 * 1000);
      }

      if (toState.params['scrollTo']) {
        this.stateService.scrollToElement();
      }
    });

    /**
     * Other services init
     */

    /**
     * Add any experiments via the URL
     */
    const experimentNames: string = this.stateService.getInitial().params.experiment;
    if (experimentNames && experimentNames.length) {
      const experimentVariants: string[] = this.stateService.getInitial().params.variant.split(',');
      experimentNames.split(',').forEach((experimentName, index): void => {
        const experiment = this.experimentService.createExperiment({
          name: experimentName,
          variant: experimentVariants[index],
          allowOverride: false
        });
        this.experimentService.addExperiment(experiment);
      });
    }
    /**
     * By Country Id
     * TODO: We could probably tidy this up a little
     */
    const overrideCountryId = this.stateService.getInitial().params.countryId;
    if (overrideCountryId) {
      const country = this.countryService.getCountryBy('id', parseInt(overrideCountryId, 10));
      this.analyticsService.trackCountryChange(this.countryService.forShipping, country);
      this.countryService.setCountryForShipping(country);
    } else {
      this.analyticsService.track('general.shipping.country', {
        countryId: this.countryService.forShipping.id
      });
      this.analyticsService.addToDataLayer({
        deliveryCountryId: this.countryService.forShipping.id
      });
      this.analyticsService.setDimension('deliveryCountry', `Default|${this.countryService.forShipping.codes[0].toUpperCase()}`);
    }

    this.analyticsService.init();

    if (this.configService.isPreviewMode()) {
      document.body.setAttribute('bw-preview', 'true');
    }

    /**
     * isCustomerDelight / lfa logic
     */
    const isCustomerDelight = !!this.localStorageService.getString('isCustomerDelight').length;
    const isLfa = this.stateService.getInitial().params.lfa;

    if (isLfa || isCustomerDelight) {
      this.analyticsService.setIsCustomerDelight();

      this.configModelService.hasRemoteConfig().then((): void => {
        const cdExperiments = (this.configService.getConfig().web_customer_delight_force_experiments || {})[
          this.countryService.forShipping.id.toString()
        ];
        this.setCDExperiments(cdExperiments);
      });
    }
    if (isLfa) {
      this.analyticsService.setDimension('isLfa', true);
      // On close of tab, reset analytics and logout
      window.addEventListener('beforeunload', (): void => {
        this.analyticsService.identify(new User()); // Reset
        this.userService.logout();
        this.contentService.refreshSegments();
        this.localStorageService.set('BW.isCustomerDelight', false);
        console.log('You have logged out');
      });
    }

    this.customKeys.init();

    // Subscribe based on params
    this.subscribeBasedOnParams();

    return this.initialLoadService
      .init()
      .then((): void => {
        this.ready = true;
        this.cookieConsent = this.cookieService.initExistingConsent();
        this.cookieSubscription$ = this.cookieService.onConsentReady().subscribe((consent): void => {
          // Used to hide the zendesk button
          this.cuntrySubscription$ = this.countryService.forShipping$.subscribe((country): void => {
            this.setDimensions(this.configService.getConfig().locale, country.id);
            this.analyticsService.setCurrency(country.currencyCode);
          });
          this.cookieConsent = consent;
        });
      })
      .finally((): void => {
        if (this.featuresService.getFeature('DIGITAL_GENIUS_WIDGET')) {
          this.digitalGeniusWidgetService.init();
        } else {
          this.displayZendesk = this.featuresService.getFeature('ZENDESK_WIDGET');
        }
      });
  }

  setDimensions(locale: string, countryId: number): void {
    this.analyticsService.setDimension('languageDisplayed', `${locale}`);

    this.analyticsService.setDimension('deliveryCountrySelected', `${countryId}`);
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    if (this.cookieSubscription$) {
      this.cookieSubscription$.unsubscribe();
      this.cuntrySubscription$.unsubscribe();
    }
  }
}
