/* eslint-disable */
export const features = {
  "gb": {
    "GDPR": {
      "doubleOptIn": false,
      "softerGDPR": false,
      "emailSubscribedPreference": 3,
      "optedInEmailPreference": 3,
      "optOutEmailPreference": 0,
      "softOptedInEmailPreference": 3,
      "availableEmailPreferences": [
        3,
        2,
        1,
        0
      ]
    },
    "B_CORP": {
      "footerLogoImage": "//images.ctfassets.net/ucgi79tscdcj/4AZwZ3wf5nmnP0ArwhkKD8/d75f0dc087abc6e3ceae5635fae0de7f/B-Corp-Logo-Black-RGB.png",
      "footerLogoStyle": "inline",
      "footerLogoActive": true
    },
    "SELF_CANCEL": true,
    "ADDRESS_SUGGEST": false,
    "ADDRESS_LOOKUP": true,
    "SHOW_MEGANAV_MOBILE_BLOG_ICON": true,
    "SHOW_APP_BANNER_FOOTER": true,
    "SHOW_TWITTER": true,
    "SHOW_CONTACT_1_FOOTER": false,
    "SHOW_CONTACT_2_FOOTER": false,
    "SHOW_CONTACT_LINK_FOOTER": true,
    "SHOW_TRUSTED_SHOP": false,
    "PREFIX_CONTENTS_API_WITH_BRAND": false,
    "REQUIRE_SPACE_IN_USER_NAME": false,
    "ADDRESS_FIELDS": {
      "firstAndSecondNameRequired": false,
      "promptForaddressLine1ToHaveNumber": false,
      "showVatNumber": false,
      "showAddressPhone": false,
      "showAddressPhoneTooltip": true,
      "showDoorCodeAsLine2Label": false,
      "showAddressPostcodeAltCopy": false,
      "postcodeOptional": false,
      "ignorePostcodeValidation": false,
      "postcodeLength": -1,
      "postcodeBeforeCity": false,
      "countryInvalidPostcodeError": true
    },
    "CONFIRMATION_POSTAL_OPT_IN": true,
    "CONFIRMATION_SHOW_PHONE": false,
    "CONFIRMATION_VAT_HEADER": false,
    "CONFIRMATION_SHOW_PROMO": false,
    "CONFIRMATION_BUY_MORE_STATUS_BAR": false,
    "CONFIRMATION_DONWLOAD_APP": true,
    "CONFIRMATION_ADDRESS_ONE_LINE": false,
    "MENTION_ME_SUBS_CAMPAIGN": true,
    "FILTER_OPTIONS": {
      "delivery": true,
      "specificDate": true,
      "packaging": true,
      "price": true,
      "type": true,
      "colours": true,
      "occasions": true,
      "petFriendly": true
    },
    "FILTER_ANIMATIONS": true,
    "PROMINENT_FILTERS": false,
    "PROMINENT_FILTER_OPTIONS": {
      "delivery": true,
      "specificDate": false,
      "packaging": false,
      "price": false,
      "type": false,
      "colours": false,
      "occasions": false,
      "petFriendly": false
    },
    "STACK_DELIVERY_FIELDS": false,
    "PAYMENT_OPTIONS": {
      "options": [
        "paypal",
        "klarna",
        "card"
      ],
      "default": "card",
      "footerIcons": [
        "visa",
        "mastercard",
        "amex",
        "paypal",
        "apple-pay",
        "google-pay",
        "klarna"
      ],
      "phonePayment": true,
      "applyCreditForSubPurchase": false
    },
    "KLARNA_ENABLED": false,
    "WEB_PAYMENT": true,
    "CONTENT_SPOT_SEO_FALLBACK": true,
    "REVIEWS-DISPLAY": true,
    "VISUAL_NAV": true,
    "VISUAL_NAV_OPTIONS": {
      "persistentSubnav": true,
      "swap": {
        "active": false,
        "key": ""
      }
    },
    "DISCOUNT_BAR": false,
    "GENERIC_SUBSCRIPTIONS": true,
    "BOUQUET_SELECTION": true,
    "SUBSCRIPTION_GRAPHIC": true,
    "CONFIRMATION_CREATE_ACCOUNT": true,
    "DELIVERY_TRACKING_MY_ACCOUNT": true,
    "DELIVERY_TRACKING_MODAL": true,
    "DELIVERY_TRACKING_SS": true,
    "FREE_SHIPPING_PILL": false,
    "GREETINGS_CARD": {
      "characterLimit": 450
    },
    "UPLOAD_FILE_INFO": true,
    "PDP_PAID_ADDON": true,
    "MENTION_ME": true,
    "COLLECTION_NAME": false,
    "SHOW_INTERNATIONAL_PICKER": true,
    "ORDER_SEND_AGAIN": true,
    "GIFT_VOUCHERS": {
      "active": true,
      "defaultVoucherValue": "35",
      "image": "//images.ctfassets.net/ucgi79tscdcj/6qmZSfrsjoKl7yTncehHih/b6df1c9288a42f1fc75639750ec35b63/UK-Digital-Gift-Card-PDP-Mock-Up.jpg",
      "showInGrid": true
    },
    "SHIPPING_OPTION": {
      "type": "carrier"
    },
    "SPECIAL_CODE": {
      "giftVoucherRedemption": false
    },
    "HAPPINESS_GUARRANTEE": true,
    "PRODUCT_PAGE_HEADER": false,
    "PDP_COLLECTION_NAME": false,
    "MENTION_ME_BANNER_WITH_IMAGE": true,
    "SHOP_AGAIN_BANNER_WITH_IMAGE": true,
    "GIFT_CARD_EDIT": true,
    "GREETING_CARDS_WIZARD_UX": false,
    "FACEBOOK_ACCESS": true,
    "GOOGLE_ACCESS": true,
    "OPEN_ACCOUNT_MENU": false,
    "ZENDESK_WIDGET": false,
    "ADDON_DISCOUNT_OVER_IMAGE": true,
    "GIFT_VOUCHER": false,
    "ORDER_DELIVERY_SELF_SERVE": true,
    "RATINGS": true,
    "SUBSCRIPTION_SHOP_NEW_UX": false,
    "MEGA_NAV_SWAP": {
      "active": true,
      "key": "UK_MULTICAT_MEGANAV_MAY_2024"
    },
    "SUBSCRIPTION_SKIP_DELIVERY": true,
    "SUBSCRIPTION_PREPAY": false,
    "SUBSCRIPTION_ADD_A_DELIVERY": true,
    "SUBSCRIPTION_MENTION_ME": true,
    "SUBSCRIPTION_ADD_DELIVERY": false,
    "SUBSCRIPTION_ACCOUNT_MENU": false,
    "SUBSCRIPTION_APPLY_DISCOUNT_CODE": true,
    "SUBSCRIPTION_CREDIT_MANAGEMENT": true,
    "SUBSCRIPTION_FREQUENCY": true,
    "SUBSCRIPTION_DAY_OF_WEEK": true,
    "SUBSCRIPTION_APPLY_ALL_DELIVERIES": true,
    "SUBSCRIPTION_PAYMENT_METHOD": true,
    "SUBSCRIPTION_PAYMENT_CONFIRM": true,
    "SUBSCRIPTION_PAYMENT_FAILED": false,
    "SUBSCRIPTION_INVOICES": true,
    "SUBSCRIPTION_VIEW_ONLY": true,
    "SUBSCRIPTION_TIMESLOT": false,
    "SUBSCRIPTION_SKIP_DELIVERIES": false,
    "SUBSCRIPTION_BENEFITS_MODAL": false,
    "SUBSCRIPTION_WINBACK": false,
    "SUBSCRIPTION_PAYMENT": {
      "billingWindow": 3
    },
    "MODULAR_PRODUCT_CARD": true,
    "GRID_FILTERS_SORT": {
      "active": true,
      "sort": true,
      "filters": true,
      "skuCount": true
    },
    "ENHANCED_CONVERSION": true,
    "GRID_SWITCHER": false,
    "GRID_SWITCHER_TRACK_INTERSECTION": false,
    "WEBPAYMENT_DEFAULT": true,
    "AUTH_REGISTER_OFFERINGS": {
      "checkout": true,
      "occasions": true,
      "account": true,
      "invoices": false
    },
    "AUTH_APPLY_FULLNAME": true,
    "CONTENT_URL_MAPPER": true,
    "TRACK_TRANSITION_EVENTS": false,
    "PEAK_DELIVERY_OPTIONS": true,
    "REVIEWS": {
      "type": "trustpilot",
      "active": true
    },
    "ADDON_ADD_CTA_SECONDARY": false,
    "SEPA_DEBIT": false,
    "GA4_CONSENT": {
      "active": true,
      "defaultValues": {
        "analytics_storage": "denied",
        "ad_storage": "denied",
        "functionality_storage": "granted",
        "personalization_storage": "granted",
        "security_storage": "granted"
      }
    },
    "SHOW_DELIVERY_DETAILS_MESSAGE": true,
    "ACCOUNT_ADDRESS_PHONE_NUMBER": false,
    "SHOW_RECIPIENT_PHONE_NUMBER": true,
    "HELP_CENTRE_V2": true,
    "CHECKOUT_PERSISTENT_STORAGE_TIME": true,
    "STICKY_DISCOUNT": false,
    "OPTIN_LOGIN": true,
    "REWARDS": true,
    "REWARDS_BIRTHDAY": true,
    "MULTI_CODE_REDEMPTION": {
      "active": true,
      "smallHeader": false
    },
    "DIGITAL_GENIUS_WIDGET": true,
    "PDP_FEATURES": {
      "VIDEOS_ENABLED": true,
      "DESCRIPTION_HEADING_ENABLED": true
    },
    "STRIPE_PAYMENT_ELEMENT": true,
    "GIFT_OPTIONS": {
      "active": true,
      "bundleUpsell": true
    },
    "USE_LOQATE_ALTERNATIVE_KEY": false,
    "JOINING_REWARDS": {
      "active": true,
      "perksAlignmentOverride": null,
      "showTandC": false,
      "showGuestContent": true
    },
    "REWARDS_VALUE_PER_PENCE": 0.05,
    "MENTION_ME_OVERLAY_SUBS": false,
    "SKU_ORDERING_ENABLED": true
  },
  "ie": {
    "GDPR": {
      "doubleOptIn": false,
      "softerGDPR": false,
      "emailSubscribedPreference": 3,
      "optedInEmailPreference": 3,
      "optOutEmailPreference": 0,
      "softOptedInEmailPreference": 3,
      "availableEmailPreferences": [
        3,
        2,
        1,
        0
      ]
    },
    "B_CORP": {
      "footerLogoImage": "//images.ctfassets.net/ucgi79tscdcj/4AZwZ3wf5nmnP0ArwhkKD8/d75f0dc087abc6e3ceae5635fae0de7f/B-Corp-Logo-Black-RGB.png",
      "footerLogoStyle": "inline",
      "footerLogoActive": true
    },
    "SELF_CANCEL": true,
    "ADDRESS_SUGGEST": false,
    "ADDRESS_LOOKUP": true,
    "SHOW_MEGANAV_MOBILE_BLOG_ICON": true,
    "SHOW_APP_BANNER_FOOTER": true,
    "SHOW_TWITTER": true,
    "SHOW_CONTACT_1_FOOTER": false,
    "SHOW_CONTACT_2_FOOTER": false,
    "SHOW_CONTACT_LINK_FOOTER": true,
    "SHOW_TRUSTED_SHOP": false,
    "PREFIX_CONTENTS_API_WITH_BRAND": false,
    "REQUIRE_SPACE_IN_USER_NAME": false,
    "ADDRESS_FIELDS": {
      "firstAndSecondNameRequired": false,
      "promptForaddressLine1ToHaveNumber": false,
      "showVatNumber": false,
      "showAddressPhone": true,
      "showAddressPhoneTooltip": true,
      "showDoorCodeAsLine2Label": false,
      "showAddressPostcodeAltCopy": true,
      "postcodeOptional": false,
      "ignorePostcodeValidation": false,
      "postcodeLength": -1,
      "postcodeBeforeCity": false,
      "countryInvalidPostcodeError": true
    },
    "CONFIRMATION_POSTAL_OPT_IN": false,
    "CONFIRMATION_SHOW_PHONE": false,
    "CONFIRMATION_VAT_HEADER": false,
    "CONFIRMATION_SHOW_PROMO": false,
    "CONFIRMATION_BUY_MORE_STATUS_BAR": false,
    "CONFIRMATION_DONWLOAD_APP": true,
    "CONFIRMATION_ADDRESS_ONE_LINE": false,
    "MENTION_ME_SUBS_CAMPAIGN": true,
    "FILTER_OPTIONS": {
      "delivery": true,
      "specificDate": true,
      "packaging": true,
      "price": true,
      "type": true,
      "colours": true,
      "occasions": true,
      "petFriendly": true
    },
    "FILTER_ANIMATIONS": true,
    "PROMINENT_FILTERS": false,
    "PROMINENT_FILTER_OPTIONS": {
      "delivery": false,
      "specificDate": false,
      "packaging": false,
      "price": false,
      "type": false,
      "colours": false,
      "occasions": false,
      "petFriendly": false
    },
    "STACK_DELIVERY_FIELDS": false,
    "PAYMENT_OPTIONS": {
      "options": [
        "paypal",
        "card"
      ],
      "default": "card",
      "footerIcons": [
        "visa",
        "mastercard",
        "amex",
        "paypal",
        "apple-pay",
        "google-pay"
      ],
      "phonePayment": true,
      "applyCreditForSubPurchase": false
    },
    "KLARNA_ENABLED": false,
    "WEB_PAYMENT": true,
    "CONTENT_SPOT_SEO_FALLBACK": true,
    "REVIEWS-DISPLAY": true,
    "VISUAL_NAV": false,
    "VISUAL_NAV_OPTIONS": {
      "persistentSubnav": false,
      "swap": {
        "active": false,
        "key": ""
      }
    },
    "DISCOUNT_BAR": false,
    "GENERIC_SUBSCRIPTIONS": false,
    "BOUQUET_SELECTION": false,
    "SUBSCRIPTION_GRAPHIC": false,
    "CONFIRMATION_CREATE_ACCOUNT": true,
    "DELIVERY_TRACKING_MY_ACCOUNT": false,
    "DELIVERY_TRACKING_MODAL": false,
    "DELIVERY_TRACKING_SS": false,
    "FREE_SHIPPING_PILL": false,
    "GREETINGS_CARD": {
      "characterLimit": 450
    },
    "UPLOAD_FILE_INFO": false,
    "PDP_PAID_ADDON": true,
    "MENTION_ME": true,
    "COLLECTION_NAME": false,
    "SHOW_INTERNATIONAL_PICKER": true,
    "ORDER_SEND_AGAIN": true,
    "GIFT_VOUCHERS": {
      "active": false,
      "defaultVoucherValue": "",
      "image": "//images.ctfassets.net/ucgi79tscdcj/6qmZSfrsjoKl7yTncehHih/b6df1c9288a42f1fc75639750ec35b63/UK-Digital-Gift-Card-PDP-Mock-Up.jpg",
      "showInGrid": true
    },
    "SHIPPING_OPTION": {
      "type": "carrier"
    },
    "SPECIAL_CODE": {
      "giftVoucherRedemption": false
    },
    "HAPPINESS_GUARRANTEE": true,
    "PRODUCT_PAGE_HEADER": false,
    "PDP_COLLECTION_NAME": false,
    "MENTION_ME_BANNER_WITH_IMAGE": true,
    "SHOP_AGAIN_BANNER_WITH_IMAGE": true,
    "GIFT_CARD_EDIT": true,
    "GREETING_CARDS_WIZARD_UX": false,
    "FACEBOOK_ACCESS": true,
    "GOOGLE_ACCESS": true,
    "OPEN_ACCOUNT_MENU": false,
    "ZENDESK_WIDGET": false,
    "ADDON_DISCOUNT_OVER_IMAGE": true,
    "GIFT_VOUCHER": false,
    "ORDER_DELIVERY_SELF_SERVE": true,
    "RATINGS": true,
    "SUBSCRIPTION_SHOP_NEW_UX": false,
    "MEGA_NAV_SWAP": {
      "active": false,
      "key": ""
    },
    "SUBSCRIPTION_SKIP_DELIVERY": true,
    "SUBSCRIPTION_PREPAY": false,
    "SUBSCRIPTION_ADD_A_DELIVERY": true,
    "SUBSCRIPTION_MENTION_ME": true,
    "SUBSCRIPTION_ADD_DELIVERY": false,
    "SUBSCRIPTION_ACCOUNT_MENU": false,
    "SUBSCRIPTION_APPLY_DISCOUNT_CODE": true,
    "SUBSCRIPTION_CREDIT_MANAGEMENT": true,
    "SUBSCRIPTION_FREQUENCY": true,
    "SUBSCRIPTION_DAY_OF_WEEK": true,
    "SUBSCRIPTION_APPLY_ALL_DELIVERIES": true,
    "SUBSCRIPTION_PAYMENT_METHOD": true,
    "SUBSCRIPTION_PAYMENT_CONFIRM": true,
    "SUBSCRIPTION_PAYMENT_FAILED": false,
    "SUBSCRIPTION_INVOICES": true,
    "SUBSCRIPTION_VIEW_ONLY": true,
    "SUBSCRIPTION_TIMESLOT": false,
    "SUBSCRIPTION_SKIP_DELIVERIES": false,
    "SUBSCRIPTION_BENEFITS_MODAL": false,
    "SUBSCRIPTION_WINBACK": false,
    "SUBSCRIPTION_PAYMENT": {
      "billingWindow": 3
    },
    "MODULAR_PRODUCT_CARD": true,
    "GRID_FILTERS_SORT": {
      "active": true,
      "sort": true,
      "filters": true,
      "skuCount": true
    },
    "ENHANCED_CONVERSION": true,
    "GRID_SWITCHER": false,
    "GRID_SWITCHER_TRACK_INTERSECTION": false,
    "WEBPAYMENT_DEFAULT": false,
    "AUTH_REGISTER_OFFERINGS": {
      "checkout": true,
      "occasions": true,
      "account": true,
      "invoices": false
    },
    "AUTH_APPLY_FULLNAME": true,
    "CONTENT_URL_MAPPER": true,
    "TRACK_TRANSITION_EVENTS": false,
    "PEAK_DELIVERY_OPTIONS": false,
    "REVIEWS": {
      "type": "trustpilot",
      "active": true
    },
    "ADDON_ADD_CTA_SECONDARY": false,
    "SEPA_DEBIT": false,
    "GA4_CONSENT": {
      "active": true,
      "defaultValues": {
        "analytics_storage": "denied",
        "ad_storage": "denied",
        "functionality_storage": "granted",
        "personalization_storage": "granted",
        "security_storage": "granted"
      }
    },
    "SHOW_DELIVERY_DETAILS_MESSAGE": true,
    "ACCOUNT_ADDRESS_PHONE_NUMBER": false,
    "SHOW_RECIPIENT_PHONE_NUMBER": false,
    "HELP_CENTRE_V2": true,
    "CHECKOUT_PERSISTENT_STORAGE_TIME": true,
    "STICKY_DISCOUNT": false,
    "OPTIN_LOGIN": true,
    "REWARDS": false,
    "REWARDS_BIRTHDAY": false,
    "MULTI_CODE_REDEMPTION": {
      "active": true,
      "smallHeader": false
    },
    "DIGITAL_GENIUS_WIDGET": true,
    "PDP_FEATURES": {
      "VIDEOS_ENABLED": true,
      "DESCRIPTION_HEADING_ENABLED": true
    },
    "STRIPE_PAYMENT_ELEMENT": true,
    "GIFT_OPTIONS": {
      "active": true,
      "bundleUpsell": true
    },
    "USE_LOQATE_ALTERNATIVE_KEY": true,
    "JOINING_REWARDS": {
      "active": false,
      "perksAlignmentOverride": null,
      "showTandC": false,
      "showGuestContent": true
    },
    "REWARDS_VALUE_PER_PENCE": 0.05,
    "MENTION_ME_OVERLAY_SUBS": false,
    "SKU_ORDERING_ENABLED": true
  },
  "de": {
    "GDPR": {
      "doubleOptIn": true,
      "softerGDPR": false,
      "emailSubscribedPreference": 4,
      "optedInEmailPreference": 4,
      "optOutEmailPreference": 0,
      "softOptedInEmailPreference": 3,
      "availableEmailPreferences": [
        3,
        2,
        1,
        0
      ]
    },
    "B_CORP": {
      "footerLogoImage": "//images.ctfassets.net/ucgi79tscdcj/4AZwZ3wf5nmnP0ArwhkKD8/d75f0dc087abc6e3ceae5635fae0de7f/B-Corp-Logo-Black-RGB.png",
      "footerLogoStyle": "inline",
      "footerLogoActive": true
    },
    "SELF_CANCEL": true,
    "ADDRESS_SUGGEST": true,
    "ADDRESS_LOOKUP": false,
    "SHOW_MEGANAV_MOBILE_BLOG_ICON": true,
    "SHOW_APP_BANNER_FOOTER": true,
    "SHOW_TWITTER": true,
    "SHOW_CONTACT_1_FOOTER": false,
    "SHOW_CONTACT_2_FOOTER": false,
    "SHOW_CONTACT_LINK_FOOTER": true,
    "SHOW_TRUSTED_SHOP": false,
    "PREFIX_CONTENTS_API_WITH_BRAND": false,
    "REQUIRE_SPACE_IN_USER_NAME": false,
    "ADDRESS_FIELDS": {
      "firstAndSecondNameRequired": true,
      "promptForaddressLine1ToHaveNumber": true,
      "showVatNumber": false,
      "showAddressPhone": false,
      "showAddressPhoneTooltip": false,
      "showDoorCodeAsLine2Label": false,
      "showAddressPostcodeAltCopy": false,
      "postcodeOptional": false,
      "ignorePostcodeValidation": false,
      "postcodeLength": 5,
      "postcodeBeforeCity": true,
      "countryInvalidPostcodeError": true
    },
    "CONFIRMATION_POSTAL_OPT_IN": true,
    "CONFIRMATION_SHOW_PHONE": false,
    "CONFIRMATION_VAT_HEADER": false,
    "CONFIRMATION_SHOW_PROMO": false,
    "CONFIRMATION_BUY_MORE_STATUS_BAR": false,
    "CONFIRMATION_DONWLOAD_APP": true,
    "CONFIRMATION_ADDRESS_ONE_LINE": false,
    "MENTION_ME_SUBS_CAMPAIGN": true,
    "FILTER_OPTIONS": {
      "delivery": true,
      "specificDate": true,
      "packaging": true,
      "price": true,
      "type": true,
      "colours": true,
      "occasions": true,
      "petFriendly": true
    },
    "FILTER_ANIMATIONS": true,
    "PROMINENT_FILTERS": false,
    "PROMINENT_FILTER_OPTIONS": {
      "delivery": false,
      "specificDate": false,
      "packaging": false,
      "price": false,
      "type": false,
      "colours": false,
      "occasions": false,
      "petFriendly": false
    },
    "STACK_DELIVERY_FIELDS": true,
    "PAYMENT_OPTIONS": {
      "options": [
        "paypal",
        "card"
      ],
      "default": "card",
      "footerIcons": [
        "paypal",
        "visa",
        "mastercard",
        "amex",
        "apple-pay",
        "google-pay"
      ],
      "phonePayment": true,
      "applyCreditForSubPurchase": false
    },
    "WEB_PAYMENT": true,
    "CONTENT_SPOT_SEO_FALLBACK": true,
    "REVIEWS-DISPLAY": true,
    "VISUAL_NAV": true,
    "VISUAL_NAV_OPTIONS": {
      "persistentSubnav": false,
      "swap": {
        "active": false,
        "key": ""
      }
    },
    "DISCOUNT_BAR": false,
    "GENERIC_SUBSCRIPTIONS": false,
    "BOUQUET_SELECTION": true,
    "SUBSCRIPTION_GRAPHIC": false,
    "CONFIRMATION_CREATE_ACCOUNT": true,
    "DELIVERY_TRACKING_MY_ACCOUNT": false,
    "DELIVERY_TRACKING_MODAL": false,
    "DELIVERY_TRACKING_SS": false,
    "FREE_SHIPPING_PILL": false,
    "GREETINGS_CARD": {
      "characterLimit": 450
    },
    "UPLOAD_FILE_INFO": false,
    "PDP_PAID_ADDON": true,
    "MENTION_ME": true,
    "COLLECTION_NAME": false,
    "SHOW_INTERNATIONAL_PICKER": true,
    "ORDER_SEND_AGAIN": true,
    "GIFT_VOUCHERS": {
      "active": true,
      "defaultVoucherValue": "35",
      "image": "//images.ctfassets.net/ucgi79tscdcj/2Dbb8LNgS4cZBBcmyPjgkb/ffc618e6d1cb8657912e6aff28d45604/DE-Digital-Gift-Card-PDP-Mock-Up.jpg",
      "showInGrid": true
    },
    "SHIPPING_OPTION": {
      "type": "carrier"
    },
    "SPECIAL_CODE": {
      "giftVoucherRedemption": false
    },
    "HAPPINESS_GUARRANTEE": false,
    "PRODUCT_PAGE_HEADER": false,
    "PDP_COLLECTION_NAME": false,
    "MENTION_ME_BANNER_WITH_IMAGE": true,
    "SHOP_AGAIN_BANNER_WITH_IMAGE": true,
    "GIFT_CARD_EDIT": true,
    "GREETING_CARDS_WIZARD_UX": false,
    "FACEBOOK_ACCESS": true,
    "GOOGLE_ACCESS": true,
    "OPEN_ACCOUNT_MENU": false,
    "ZENDESK_WIDGET": false,
    "ADDON_DISCOUNT_OVER_IMAGE": true,
    "GIFT_VOUCHER": false,
    "ORDER_DELIVERY_SELF_SERVE": true,
    "RATINGS": true,
    "SUBSCRIPTION_SHOP_NEW_UX": false,
    "MEGA_NAV_SWAP": {
      "active": false,
      "key": ""
    },
    "SUBSCRIPTION_SKIP_DELIVERY": true,
    "SUBSCRIPTION_PREPAY": false,
    "SUBSCRIPTION_ADD_A_DELIVERY": true,
    "SUBSCRIPTION_MENTION_ME": true,
    "SUBSCRIPTION_ADD_DELIVERY": false,
    "SUBSCRIPTION_ACCOUNT_MENU": false,
    "SUBSCRIPTION_APPLY_DISCOUNT_CODE": true,
    "SUBSCRIPTION_CREDIT_MANAGEMENT": true,
    "SUBSCRIPTION_FREQUENCY": false,
    "SUBSCRIPTION_DAY_OF_WEEK": true,
    "SUBSCRIPTION_APPLY_ALL_DELIVERIES": true,
    "SUBSCRIPTION_PAYMENT_METHOD": true,
    "SUBSCRIPTION_PAYMENT_CONFIRM": true,
    "SUBSCRIPTION_PAYMENT_FAILED": false,
    "SUBSCRIPTION_INVOICES": true,
    "SUBSCRIPTION_VIEW_ONLY": true,
    "SUBSCRIPTION_TIMESLOT": false,
    "SUBSCRIPTION_SKIP_DELIVERIES": false,
    "SUBSCRIPTION_BENEFITS_MODAL": false,
    "SUBSCRIPTION_WINBACK": false,
    "SUBSCRIPTION_PAYMENT": {
      "billingWindow": 3
    },
    "MODULAR_PRODUCT_CARD": true,
    "GRID_FILTERS_SORT": {
      "active": true,
      "sort": true,
      "filters": true,
      "skuCount": true
    },
    "ENHANCED_CONVERSION": true,
    "GRID_SWITCHER": false,
    "GRID_SWITCHER_TRACK_INTERSECTION": false,
    "WEBPAYMENT_DEFAULT": false,
    "AUTH_REGISTER_OFFERINGS": {
      "checkout": true,
      "occasions": false,
      "account": true,
      "invoices": true
    },
    "AUTH_APPLY_FULLNAME": true,
    "CONTENT_URL_MAPPER": true,
    "TRACK_TRANSITION_EVENTS": false,
    "PEAK_DELIVERY_OPTIONS": false,
    "REVIEWS": {
      "type": "trustedshops",
      "active": true
    },
    "ADDON_ADD_CTA_SECONDARY": false,
    "SEPA_DEBIT": false,
    "GA4_CONSENT": {
      "active": true,
      "defaultValues": {
        "analytics_storage": "denied",
        "ad_storage": "denied",
        "functionality_storage": "granted",
        "personalization_storage": "granted",
        "security_storage": "granted"
      }
    },
    "SHOW_DELIVERY_DETAILS_MESSAGE": true,
    "ACCOUNT_ADDRESS_PHONE_NUMBER": false,
    "SHOW_RECIPIENT_PHONE_NUMBER": false,
    "HELP_CENTRE_V2": true,
    "CHECKOUT_PERSISTENT_STORAGE_TIME": true,
    "STICKY_DISCOUNT": false,
    "OPTIN_LOGIN": true,
    "REWARDS": false,
    "REWARDS_BIRTHDAY": false,
    "MULTI_CODE_REDEMPTION": {
      "active": true,
      "smallHeader": false
    },
    "KLARNA_ENABLED": false,
    "DIGITAL_GENIUS_WIDGET": true,
    "PDP_FEATURES": {
      "VIDEOS_ENABLED": false,
      "DESCRIPTION_HEADING_ENABLED": true
    },
    "STRIPE_PAYMENT_ELEMENT": true,
    "GIFT_OPTIONS": {
      "active": true,
      "bundleUpsell": false
    },
    "USE_LOQATE_ALTERNATIVE_KEY": false,
    "JOINING_REWARDS": {
      "active": false,
      "perksAlignmentOverride": null,
      "showTandC": false,
      "showGuestContent": true
    },
    "REWARDS_VALUE_PER_PENCE": 0.05,
    "MENTION_ME_OVERLAY_SUBS": false,
    "SKU_ORDERING_ENABLED": true
  }
};