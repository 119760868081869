import { Component, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, NgIf, NgFor } from '@angular/common';
import { Label } from 'Shared/classes/product';
import { OasysImageModule, OasysLayoutModule, OasysIconModule, OasysLabelModule } from 'oasys-lib';

@Component({
  selector: 'bw-product-card-label',
  templateUrl: './product-card-label.component.html',
  styleUrls: ['./product-card-label.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, NgIf, NgFor, OasysLayoutModule, OasysImageModule, OasysIconModule, OasysLabelModule]
})
export class ProductCardLabelComponent {
  @Input() productLabels: Label[];

  constructor() {}
}
