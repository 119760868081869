/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { heapAnalyticsMappings } from 'Environments/heapmappings';

import { Order, Purchase } from 'Checkout/services/purchase.service';
import { CarouselSegment } from 'Shared/models/segment-model.service';
import { UserService } from 'Shared/services/user.service';
import { Card } from 'Shared/classes/card';
import { Product, Label } from 'Shared/classes/product';
import { Experiment } from 'Shared/classes/experiment';
import { Error } from 'Shared/classes/error';
import { StateService } from 'Shared/services/state.service';
import { LocationService } from 'Shared/services/location.service';
import { OccasionReminder } from 'Shared/classes/occasion-reminder';
import { GridProduct } from 'Shared/classes/grid-product';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { ConfigService } from 'Shared/services/config.service';
import { Addon } from 'Shared/classes/addon';
import { ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { FeaturesService } from '../features.service';
import { Price } from 'Shared/classes/price';
import { ReturningCustomerService } from '../returning-customer.service';
import { ClaimedReward, LoyaltyPointBalance, LoyaltyReward, LoyaltyMembership } from 'Shared/classes/loyalty';
import { error } from 'console';

export interface HeapInterface {
  accountStatus?: 'registered' | 'guest' | 'none';
  addedFilters?: string;
  addedFiltersAlphabetical?: string;
  addon?: Addon;
  addonPosition?: number;
  activeCarouselSegment?: CarouselSegment;
  activeFilters?: string;
  activeFiltersAlphabetical?: string;
  breadcrumb?: string;
  breadcrumbState?: string;
  card?: Card;
  carouselLength?: number;
  carouselSegment?: number;
  carouselSortType?: string;
  checkoutType?: string;
  clicktext?: string;
  compensationType?: string;
  componentName?: string;
  content?: string;
  contentfulPageUrl?: string;
  contentfulPageTitle?: string;
  contentSegments?: number[];
  contentSpot?: boolean;
  daysUntilDeliverable?: number;
  deliveryId?: string;
  deliveryState?: string;
  deliveryMessage?: string;
  deliveryMovedFrom?: string;
  deliveryMovedTo?: string;
  deliveryTrackingState?: string;
  skippedDeliveryDate?: string;
  deliveryDate?: string;
  skipLocation?: string;
  numberOfSkips?: number;
  details?: string;
  engagementCta?: string;
  error?: Error;
  errorMessage?: string;
  errorBody?: string;
  experiment?: Experiment;
  filterName?: string;
  filterType?: string;
  filterValue?: string;
  gridViewType?: 'oneColumn' | 'twoColumns';
  guestOptInSelection?: string;
  hasAccount?: boolean;
  hasRegistered?: boolean;
  issue?: string;
  issueOptionId?: string;
  isSubFlowExperiment?: Experiment;
  isSuccessful?: boolean;
  listType?: {
    value?: string;
    type?: string;
  };
  mainNavItem?: string;
  mainNavItemNew?: boolean;
  media?: string;
  modalLocation?: string;
  modalScreen?: string;
  modalType?: string;
  modularProductCardCtaCopy?: string;
  modularProductCardCUrclickon?: string;
  modularProductCardHasCta?: boolean;
  modularProductCardId?: number;
  modularProductCardIndex?: number;
  modularProductCardMessage?: string;
  modularProductCardName?: string;
  modularProductCardSource?: string;
  navStyle?: string;
  occasion?: OccasionReminder;
  optedIn?: boolean;
  order?: Order;
  orderId?: string;
  placement?: string;
  primaryCta?: string;
  primarySubNavItem?: string;
  primarySubNavItemNew?: boolean;
  product?: Product | GridProduct;
  productCardLabel?: Label[];
  productFindingMethod?: string;
  productListIds?: string;
  productListUniqueIndentifier?: string;
  attempts?: number;
  productPosition?: number;
  productUndeliverableTomorrow?: boolean;
  // TODO: remove once all events updated to use voucher and discount properties
  promoCodeApplied?: string;
  promoCodeDetail?: string;
  purchase?: Purchase;
  ranking?: number;
  reason?: string;
  referrerUrl?: string;
  removedFilters?: string;
  removedFiltersAlphabetical?: string;
  secondaryCta?: string;
  secondarySubNavItem?: string;
  secondarySubNavItemNew?: boolean;
  shippingCountryId?: number;
  shippingDateStatus?: 'Available' | 'Other options available' | 'Not available';
  skuList?: string;
  skuTotal?: number;
  stems?: string[];
  subNavLength?: number;
  subNavPosition?: number;
  subsDuration?: string;
  subsFrequency?: number;
  fromSubFrequency?: number;
  subsType?: string;
  tabSelected?: string;
  trackingState?: string;
  type?: string;
  updateUpcomingDeliveriesChecked?: boolean;
  user?: User;
  userType?: string;
  verificaitonDisplayed?: boolean;
  verificaitonRequested?: boolean;
  visualNavItem?: string;
  visualNavSubNavItem?: string;
  activeCtaUrl?: string;
  contentPosition?: string;
  optimizelyUserId?: string;
  visitorType?: string;
  scenario?: string;
  message?: string;
  origin?: string;
  discountCodeType?: string;
  discountCodeDetail?: string;
  discountCode?: string;
  discountCodeApplication?: string;
  rewardsMember?: boolean;
  rewardsVoucherCode?: string;
  rewardsVoucherValue?: string;
  rewardsVoucherExpiry?: string;
  rewardsVoucherValuePoints?: string;
  rewardsVoucherApplication?: string;
  rewardsWidgetLocation?: string;
  rewardsHomeCTAMessage?: string;
  claimedRewardsVouchers?: number;
  claimedRewardsValue?: number;
  rewardsAvailable?: boolean;
  rewardsWidgetVoucherValue?: number;
  rewardsWidgetAvailablePointsValue?: number;
  rewardsWidgetPendingPointsValue?: number;
  rewardsWidgetPointsToNextRewardValue?: number;
  rewardsShopLocation?: string;
  rewardsPoints?: LoyaltyPointBalance;
  nextRewards?: LoyaltyReward;
  claimedRewards?: ClaimedReward[];
  reward?: ClaimedReward;
  loyaltyMembership?: LoyaltyMembership;
  languageDisplayed?: string;
  codeEntered?: string;
  codeApplication?: string;
  rewardsSignUpLocation?: string;
  rewardsMemberType?: string;
  rewardsSignUpSource?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_source?: string;
  assetPosition?: number;
  assetType?: 'image' | 'video';
  errorLocation?: string;
  dayInput?: string;
  monthInput?: string;
  yearInput?: string;
  greetingCardSku?: number;
  greetingCardPrice?: string;
  greetingCardName?: string;
  oldPreference?: string;
  newPreference?: string;
  detail?: string;
  location?: string;
  totalAddonAdded?: number;
  totalFreeAddon?: number;
  totalPaidAddon?: number;
  totalAddonPrice?: string;
  totalAddonList?: string;
  totalAddonNameList?: string;
  categoryListName?: string;
  cardName?: string;
  cardUrl?: string;
  deliveryTypeDisplayed?: string;
  totalDeliveriesDisplayed?: number;
  skippedDeliveriesDisplayed?: number;
  deliveryDatesDisplayed?: string[];
  infoMessageDisplayed?: string;
  addedDeliveryDatePosition?: number;
  activeSubsDeliveries?: number;
  skippedSubsDeliveries?: number;
  winbackType?: string;
  churnReason?: string;
  churnType?: string;
  playVideoBy?: string;
  winbackDetail?: string;
  delayDeliveriesUntil?: string;
  rewardsJoinOrigin?: string;
  existingBouquet?: string;
  newBouquet?: string;
  sentimentFeedback?: 'positive' | 'negative';
}

/**
 * Heap
 * https://docs.heapanalytics.com/reference#track
 *
 * Note - Heap gets injected into the page through GTM, but we should probably look to improve this
 */
@Injectable({
  providedIn: 'root'
})
export class HeapService {
  serviceInitialized: boolean = false;
  debug: boolean = false;
  window: any;
  enabled: boolean = true;
  registeredEvents: any[] = [];
  waitForTimer: any;
  initPromise: Promise<any>;
  heapAccountId: string;

  // Some properties must presist once a component/state has been destroyed
  heapGlobalProperties: HeapInterface = {};
  promoCodeAppliedEventHasFired: boolean = false;

  firstStartCheckOutAddress: boolean = false;
  experiments: Experiment[] = [];

  constructor(
    private windowRef: WindowRefService,
    private userService: UserService,
    private state: StateService,
    private locationService: LocationService,
    private domUtilsService: DomUtilsService,
    private configService: ConfigService,
    private viewportDetectionService: ViewportDetectionService,
    private featureService: FeaturesService,
    private returningCustomerService: ReturningCustomerService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.heapAccountId = this.configService.getConfig().heapAccountId;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
    this.registeredEvents = heapAnalyticsMappings;
  }

  setGlobalEventProperty(property: HeapInterface): void {
    const globalProperties = this.getGlobalHeapProperties();
    this.heapGlobalProperties = Object.assign(globalProperties, property);
  }

  getGlobalHeapProperties(): HeapInterface {
    return this.heapGlobalProperties;
  }

  /**
   * Call the heap Library
   * @param fnName
   * @param args
   */
  heap(fnName: string, ...args: string[]): void {
    if (this.serviceInitialized && this.window.heap && this.window.heap[fnName]) {
      try {
        this.window.heap[fnName](...args);
        this.log(fnName, ...args);
      } catch (e) {}
    }
  }

  /**
   * Log
   */
  log(...args: string[]): void {
    if (this.debug) {
      console.log('<heap>', ...args);
    }
  }

  /**
   * Identify the user
   * @param user
   */
  identify(user: User): void {
    try {
      if (user.slug) {
        this.heap('identify', user.slug);
        return;
      }
      this.heap('resetIdentity');
    } catch (e) {}
  }

  /**
   * Set the event properties
   * @param obj
   */
  setEventProperties(obj: any): void {
    this.heap('addEventProperties', obj);
  }

  /**
   *
   * @param eventName
   * @param obj
   */
  logEvent(eventName: string, obj: HeapInterface | any): void {
    this.heap('track', eventName, obj);
  }

  /**
   * Log an event within heap
   * @param obj
   */
  logAdvancedEvent(eventName: string, heapData: HeapInterface): void {
    const registeredEvent = this.registeredEvents.find((event): boolean => event.name === eventName);

    if (!registeredEvent) {
      this.log(eventName, 'not found');
      return;
    }

    if (
      (eventName === 'codeAppliedSuccess' && (heapData.purchase?.discount || heapData.purchase?.giftVouchers?.length)) ||
      eventName === 'codeAppliedAttempt'
    ) {
      this.promoCodeAppliedEventHasFired = true;
    }

    if (!heapData.productPosition && this.getGlobalHeapProperties().productPosition >= 0) {
      heapData.productPosition = this.getGlobalHeapProperties().productPosition + 1;
    }

    if (!heapData?.location) {
      const currentState = this.state?.getCurrent?.();
      heapData.location = currentState?.from?.name === 'homepage' ? '/' : currentState?.from?.url;
    }

    // TODO: These will need to be added, but for now, this is OK for initial testing
    // prettier-ignore-start
    heapData.listType = heapData.listType ?? this.locationService.getListType();
    // heapData.purchase = heapData.purchase ?? this.purchaseService.getPurchase();
    heapData.user = heapData.user ?? this.userService.getUser();

    // Checking if discount was applied via query params
    if (this.state.getInitial().params?.['discountCode']) {
      heapData.discountCodeApplication = 'automatic';
      heapData.promoCodeApplied = 'automatic';
      heapData.codeApplication = 'automatic';
    }

    // Checking if discount was applied via url i.e /credit/cc340
    if (
      !this.promoCodeAppliedEventHasFired &&
      this.state.getInitial().params &&
      !this.state.getInitial().params?.['discountCode'] &&
      heapData.purchase &&
      heapData.purchase.discount &&
      heapData.purchase.discount.code
    ) {
      heapData.discountCodeApplication = 'automatic';
      heapData.promoCodeApplied = 'automatic';
      heapData.codeApplication = 'automatic';
    }

    if (!this.promoCodeAppliedEventHasFired && heapData.purchase?.giftVouchers?.length) {
      heapData.rewardsVoucherApplication = 'automatic';
      heapData.codeApplication = 'automatic';
    }

    if (this.promoCodeAppliedEventHasFired) {
      heapData.discountCodeApplication = heapData?.discountCodeApplication ?? 'manual';
      heapData.rewardsVoucherApplication = heapData?.rewardsVoucherApplication ?? 'manual';
      heapData.promoCodeApplied = heapData?.promoCodeApplied ?? 'manual';
      heapData.codeApplication = heapData?.codeApplication ?? 'manual';
    }

    // heapData.contentSegments = heapData.contentSegments ?? this.contentService.getContentSegment() ?? [];

    const product: Product | GridProduct = heapData.product ? heapData.product : heapData.order ? heapData.order.product : undefined;

    // Different ways of calculating the price

    let productPrice: Price;

    const currentParams = this.state.getCurrent().params;

    try {
      if (product) {
        if (product.price && product.price.price) {
          productPrice = product.price;
        }
        const price = product.getPrice();
        if (!productPrice && price && price) {
          productPrice = price;
        }
        if (!productPrice && heapData.order) {
          productPrice = heapData.order.price;
        }
        // Specifically for productImpression event, because the prices on the carousel differ based on variant and type
        if (eventName === 'productImpression' && heapData.listType.value === 'custom-subscription') {
          // If seeing subscription only carousel then get ongoing subs price, otherwise set the 3 month bundle price
          const p =
            currentParams && currentParams.type === 'subscription'
              ? product.getSubscriptionPrice()
              : currentParams.type === 'bundle'
                ? product.getPriceFor(3, 28)
                : undefined;

          productPrice = p;
        }
        // For the subs flow experiment
        if (heapData.order && heapData.listType.value === 'custom-subscription') {
          productPrice = product.getPriceFor(heapData.order.duration, heapData.order.frequency);
        }
      }
    } catch (e) {}

    // checkoutType
    const checkoutType = this.getGlobalHeapProperties().checkoutType;
    if (checkoutType) {
      heapData.checkoutType = checkoutType;
    }

    // product finding method
    const productFindingMethod = this.getGlobalHeapProperties().productFindingMethod;
    if (productFindingMethod) {
      heapData.productFindingMethod = productFindingMethod;
    }

    // carousel length
    const carouselLength = this.getGlobalHeapProperties().carouselLength;
    if (carouselLength && !heapData.carouselLength) {
      heapData.carouselLength = carouselLength;
    }

    // whether user is registered or not
    const hasRegistered = this.getGlobalHeapProperties().hasRegistered;

    // if user already has the hasRegistered property set then use it
    if (heapData.user && heapData.user.email && heapData.user.email.hasRegistered) {
      heapData.hasRegistered = heapData.user.email.hasRegistered;
    } else if (heapData.user && heapData.user.email && !heapData.user.email.hasRegistered) {
      // get the property from the observable object
      heapData.hasRegistered = hasRegistered;
    }

    // If carousel segment
    if (!heapData.activeCarouselSegment) {
      const activeCarouselSegment = this.getGlobalHeapProperties().activeCarouselSegment;
      if (activeCarouselSegment) {
        heapData.activeCarouselSegment = activeCarouselSegment;
      }
    }

    // if user is loggedIn then set registered to true
    if (heapData.user && heapData.user.isLoggedIn()) {
      heapData.hasRegistered = true;
    }

    if (heapData.purchase && heapData.purchase.discount && heapData.purchase.discount.percentage) {
      heapData.promoCodeDetail = `${heapData.purchase.discount.percentage}%`;
    }

    if (
      heapData.purchase &&
      heapData.purchase.discount &&
      heapData.purchase.discount.percentage === null &&
      heapData.purchase.discount.amountPennies
    ) {
      heapData.promoCodeDetail = `${(heapData.purchase.discount.amountPennies / 100).toFixed(2)}`;
    }

    // If it's a valid address
    let addressType: string;
    if (
      heapData.order &&
      heapData.order.address &&
      heapData.order.address.name &&
      heapData.order.address.line1 &&
      heapData.order.address.postcode
    ) {
      addressType = heapData.order.address.id ? 'saved' : 'new';
    }

    const params = {
      modalType: heapData.modalType,
      carouselTag: heapData.listType ? heapData.listType.value : undefined,
      carouselType: heapData.listType ? heapData.listType.type : undefined,
      carouselLength: heapData.carouselLength ? heapData.carouselLength : undefined,
      filterName: heapData.filterName,
      filterValue: heapData.filterValue,
      filterType: heapData.filterType,
      addedFiltersAlphabetical: heapData.addedFiltersAlphabetical,
      addedFilters: heapData.addedFilters,
      removedFiltersAlphabetical: heapData.removedFiltersAlphabetical,
      removedFilters: heapData.removedFilters,
      activeFiltersAlphabetical: heapData.activeFiltersAlphabetical,
      activeFilters: heapData.activeFilters,
      checkoutType: heapData.checkoutType ? heapData.checkoutType : undefined,
      daysUntilDeliverable: heapData.daysUntilDeliverable,
      shippingDate: heapData.order && heapData.order.firstDeliveryDate ? heapData.order.firstDeliveryDate.format('YYYY-MM-DD') : undefined,
      shippingFee:
        heapData.order && heapData.order.shippingOption ? (heapData.order.shippingOption.price.price / 100).toFixed(2) : undefined,
      shippingOption: heapData.order && heapData.order.shippingOption ? heapData.order.shippingOption.name : undefined,
      shippingPostcode: heapData.order && heapData.order.address ? heapData.order.address.getSquishedPostcode() : undefined,
      shippingCountry:
        heapData.order &&
        heapData.order.address &&
        heapData.order.address.country &&
        heapData.order.address.country.codes &&
        heapData.order.address.country.codes.length
          ? heapData.order.address.country.codes[0]
          : undefined,
      paymentMethod: heapData.card ? heapData.card.kind : undefined,
      paymentMethodDetails: heapData.card ? heapData.card.brand : undefined,
      purchaseID: heapData.purchase ? heapData.purchase.id : undefined,
      purchaseCurrency: heapData.purchase ? heapData.purchase.currency : undefined,
      purchaseAmount: heapData.purchase && heapData.purchase.price ? (heapData.purchase.price.price / 100).toFixed(2) : undefined,
      loggedInUser: heapData.user ? heapData.user.isLoggedIn() : false,
      registeredUser: heapData.hasRegistered ? heapData.hasRegistered : false,
      contentSegments: heapData.contentSegments && heapData.contentSegments.length ? `|CS${heapData.contentSegments.join('|CS')}|` : '',
      carouselSegmentId: heapData.activeCarouselSegment ? heapData.activeCarouselSegment.segmentId : undefined,
      carouselSegments: this.getGlobalHeapProperties().activeCarouselSegment
        ? `|S${this.getGlobalHeapProperties().activeCarouselSegment.segmentId}|`
        : undefined,
      addressType: addressType,
      // preselectedAddress: preselectedAddress,
      promoCode: heapData.purchase && heapData.purchase.discount ? heapData.purchase.discount.code : undefined,
      promoCodeApplication:
        heapData.purchase && heapData.purchase.discount && heapData.purchase.discount.code ? heapData.promoCodeApplied : undefined,
      promoCodeType:
        heapData.purchase && heapData.purchase.discount
          ? heapData.purchase.discount.percentage !== null
            ? 'percent'
            : 'value'
          : undefined,
      promoCodeValue:
        heapData.purchase && heapData.purchase.discount
          ? heapData.purchase.discount.percentage && heapData.purchase.price
            ? (heapData.purchase.price.discount / 100).toFixed(2)
            : (heapData.purchase.discount.amountPennies / 100).toFixed(2)
          : undefined,
      promoCodeDetail: heapData.promoCodeDetail ? heapData.promoCodeDetail : undefined,
      promoCodeStatus: heapData.purchase && heapData.purchase.discount ? 'valid' : undefined,
      errorKind: heapData.error ? heapData.error.kind : undefined,
      errorCode: heapData.error ? heapData.error.code : undefined,
      errorMessage: heapData.error ? heapData.error.message : heapData.errorMessage ? heapData.errorMessage : undefined,
      errorBody: heapData.error ? heapData.error.errorBody : undefined,
      paymentProvider: heapData.error ? (heapData.error.meta?.source ?? heapData.error.code) : undefined,
      paymentErrorCode: heapData.error ? (heapData.error.meta?.code ?? heapData.error.code) : undefined,
      savedOccasionID: heapData.occasion ? heapData.occasion.id : undefined,
      savedOccasionName: heapData.occasion && heapData.occasion.type ? heapData.occasion.type.displayName : undefined,
      savedOccasionOtherName:
        heapData.occasion && heapData.occasion.type && heapData.occasion.type.isCustomField ? heapData.occasion.type.kind : undefined,
      savedOccasionDate:
        heapData.occasion && heapData.occasion.type.associatedDate ? heapData.occasion.type.associatedDate.format('YYYY-MM-DD') : undefined,
      savedOccasionOrigin: heapData.occasion && heapData.occasion.origin ? heapData.occasion.origin : undefined,
      experimentID: heapData.experiment && heapData.experiment.id ? heapData.experiment.id : undefined,
      experimentName: heapData.experiment && heapData.experiment.name ? heapData.experiment.name : undefined,
      experimentVariant: heapData.experiment && heapData.experiment.variant ? heapData.experiment.variant : undefined,
      productSKU: product ? product.id : undefined,
      productName: product ? product.name : undefined,
      productPrice: productPrice ? (productPrice.price / 100).toFixed(2) : undefined,
      productCurrency: productPrice ? productPrice.currency : undefined,
      productPosition: heapData.productPosition ?? undefined,
      productFindingMethod: heapData.productFindingMethod ?? undefined,
      productAvgRating: product && product.rating ? product.rating.average : undefined,
      productNumReviews: product && product.rating ? product.rating.count : undefined,
      productIsFavourite: product ? !!product['relatedFavourite'] : undefined,
      productIsBoosted: product ? !!product['isBoosted'] : undefined,
      productIsPreorder: product ? !!product['isPreorder'] : undefined,
      productIsPreviouslyPurchased: product ? !!product['previouslyPurchased'] : undefined,
      orderType: heapData.order ? heapData.order.getTrackedDurationName() : undefined,
      orderID: heapData.order ? heapData.order.id : undefined,
      activeSubsDeliveries: heapData.activeSubsDeliveries
        ? heapData.activeSubsDeliveries
        : heapData.order
          ? heapData.order.activeDeliveriesCount
          : undefined,
      churnReason: heapData.churnReason ?? undefined,
      churnType: heapData.churnType ?? undefined,
      skippedSubsDeliveries: heapData.order ? heapData.order.skippedDeliveriesCount : undefined,
      winbackType: heapData.winbackType ?? undefined,
      orderAmount: heapData.order && heapData.order.price ? (heapData.order.price.price / 100).toFixed(2) : undefined,
      orderCurrency: heapData.order && heapData.order.price ? heapData.order.price.currency : undefined,
      orderBundleDuration: heapData.order ? heapData.order.duration : undefined,
      orderBundleFrequency: heapData.order ? heapData.order.frequency : undefined,
      contentfulPageUrl: heapData.contentfulPageUrl ?? undefined,
      contentfulPageTitle: heapData.contentfulPageTitle ?? undefined,
      referrerUrl: heapData.referrerUrl ?? undefined,
      productUndeliverableTomorrow: heapData.productUndeliverableTomorrow,
      mainNavItem: heapData.mainNavItem ?? undefined,
      winbackDetail: heapData.winbackDetail ?? undefined,
      delayDeliveriesUntil: heapData.delayDeliveriesUntil ?? undefined,
      primarySubNavItem: heapData.primarySubNavItem ?? undefined,
      secondarySubNavItem: heapData.secondarySubNavItem ?? undefined,
      navStyle: heapData.navStyle ?? undefined,
      contentSpot: heapData.contentSpot ?? undefined,
      breadcrumbState: heapData.breadcrumbState ?? undefined,
      breadcrumb: heapData.breadcrumb ?? undefined,
      mainNavItemNew: heapData.mainNavItemNew ?? undefined,
      primarySubNavItemNew: heapData.primarySubNavItemNew ?? undefined,
      secondarySubNavItemNew: heapData.secondarySubNavItemNew ?? undefined,
      engagementCta: heapData.engagementCta ?? undefined,
      carouselSortType: heapData.carouselSortType ?? undefined,
      subsDuration: heapData.subsDuration ?? undefined,
      subsFrequency: heapData.subsFrequency ? heapData.subsFrequency : heapData.order ? heapData.order.frequency : undefined,
      fromSubFrequency: heapData.fromSubFrequency ?? undefined,
      skippedDeliveryDate: heapData.skippedDeliveryDate
        ? heapData.skippedDeliveryDate
        : heapData.order
          ? heapData.order.skippedDeliveriesCount
          : undefined,
      skipLocation: heapData.skipLocation ?? undefined,
      numberOfSkips: heapData.numberOfSkips ?? undefined,
      subsType: heapData.product ? heapData.product.collectionName : undefined,
      visualNavItem: heapData.visualNavItem ?? undefined,
      placement: heapData.placement ?? undefined,
      compensationType: heapData.compensationType ?? undefined,
      orderId: heapData.orderId ?? undefined,
      deliveryId: heapData.deliveryId ?? undefined,
      deliveryState: heapData.deliveryState ?? undefined,
      deliveryMessage: heapData.deliveryMessage ?? undefined,
      deliveryDate: heapData.deliveryDate ?? undefined,
      trackingState: heapData.trackingState ?? undefined,
      details: heapData.details ?? undefined,
      visualNavSubNavItem: heapData.visualNavSubNavItem ?? undefined,
      content: heapData.content ?? undefined,
      ranking: heapData.ranking ?? undefined,
      type: heapData.type ?? undefined,
      clicktext: heapData.clicktext ?? undefined,
      media: heapData.media ?? undefined,
      componentName: heapData.componentName ?? undefined,
      productListIds: heapData.productListIds ?? undefined,
      productListUniqueIndentifier: this.getGlobalHeapProperties().productListUniqueIndentifier
        ? this.getGlobalHeapProperties().productListUniqueIndentifier
        : undefined,
      modalScreen: heapData.modalScreen ?? undefined,
      attempts: heapData.attempts ?? undefined,
      deliveryTrackingState: heapData.deliveryTrackingState ?? undefined,
      modalLocation: heapData.modalLocation ?? undefined,
      issue: heapData.issue ?? undefined,
      errorLocation: heapData.errorLocation ?? undefined,
      dayInput: heapData.dayInput ?? undefined,
      monthInput: heapData.monthInput ?? undefined,
      yearInput: heapData.yearInput ?? undefined,
      stems: heapData.stems ?? undefined,
      issueOptionId: heapData.issueOptionId ?? undefined,
      userType: heapData.userType ?? undefined,
      subNavLength: heapData.subNavLength ?? undefined,
      subNavPosition: heapData.subNavPosition ?? undefined,
      shippingDateStatus: heapData.shippingDateStatus ?? undefined,
      modularProductCardIndex: heapData.modularProductCardIndex ?? undefined,
      modularProductCardHasCta: heapData.modularProductCardHasCta ?? undefined,
      primaryCta: heapData.primaryCta ?? undefined,
      secondaryCta: heapData.secondaryCta ?? undefined,
      modularProductCardCUrclickon: heapData.modularProductCardCUrclickon ?? undefined,
      modularProductCardMessage: heapData.modularProductCardMessage ?? undefined,
      modularProductCardId: heapData.modularProductCardId ?? undefined,
      modularProductCardName: heapData.modularProductCardName ?? undefined,
      modularProductCardSource: heapData.modularProductCardSource ?? undefined,
      modularProductCardCtaCopy: heapData.modularProductCardCtaCopy ?? undefined,
      gridViewType:
        this.viewportDetectionService.viewportSizeIs$.getValue()?.mobile && this.getGlobalHeapProperties().gridViewType
          ? this.getGlobalHeapProperties().gridViewType
          : undefined,
      tabSelected: heapData.tabSelected ?? undefined,
      accountStatus: heapData.accountStatus ?? undefined,
      hasAccount: heapData.hasAccount ?? undefined,
      isSuccessful: heapData.isSuccessful ?? undefined,
      optedIn: heapData.optedIn ?? undefined,
      verificaitonDisplayed: heapData.verificaitonDisplayed ?? undefined,
      verificaitonRequested: heapData.verificaitonRequested ?? undefined,
      guestOptInSelection: heapData.guestOptInSelection ?? undefined,
      skuList: heapData.skuList ?? undefined,
      skuTotal: heapData.skuTotal ?? undefined,
      productCardLabel: product?.labels ? product?.labels.map((label) => label['text']).join(',') : undefined,
      reason: heapData.reason ?? undefined,
      deliveryMovedFrom: heapData.deliveryMovedFrom ?? undefined,
      deliveryMovedTo: heapData.deliveryMovedTo ?? undefined,
      updateUpcomingDeliveriesChecked: heapData.updateUpcomingDeliveriesChecked ?? undefined,
      activeCtaUrl: heapData.activeCtaUrl ?? undefined,
      contentPosition: heapData.contentPosition ?? undefined,
      scenario: heapData.scenario ?? undefined,
      message: heapData.message ?? undefined,
      origin: heapData.origin ?? undefined,
      rewardsWidgetLocation: heapData.rewardsWidgetLocation ?? undefined,
      rewardsHomeCTAMessage: heapData.rewardsHomeCTAMessage ?? undefined,
      rewardsShopLocation: heapData.rewardsShopLocation ?? undefined,
      rewardsVoucherCode: heapData.reward?.code ?? undefined,
      rewardsVoucherValue: heapData.reward?.balance ?? undefined,
      rewardsVoucherExpiry: heapData.reward?.expiresOn?.format('YYYY-MM-DD') ?? undefined,
      codeEntered: heapData.codeEntered ?? undefined,
      codeApplication: heapData.codeApplication ?? undefined,
      rewardsSignUpSource: heapData.rewardsSignUpSource ?? undefined,
      rewardsSignUpLocation: heapData.rewardsSignUpLocation ?? undefined,
      rewardsMemberType: heapData.rewardsMemberType ?? undefined,
      utm_campaign: heapData.utm_campaign ?? undefined,
      utm_medium: heapData.utm_medium ?? undefined,
      utm_content: heapData.utm_content ?? undefined,
      utm_source: heapData.utm_source ?? undefined,
      assetPosition: heapData.assetPosition ?? undefined,
      assetType: heapData.assetType ?? undefined,
      greetingCardSku: heapData.greetingCardSku ?? undefined,
      greetingCardPrice: heapData.greetingCardPrice ?? undefined,
      greetingCardName: heapData.greetingCardName ?? undefined,
      old_preference: heapData.oldPreference ?? undefined,
      new_preference: heapData.newPreference ?? undefined,
      detail: heapData.detail ?? undefined,
      location: heapData.location ?? undefined,
      categoryListName: heapData.categoryListName ?? undefined,
      cardName: heapData.cardName ?? undefined,
      cardUrl: heapData.cardUrl ?? undefined,
      deliveryTypeDisplayed: heapData.deliveryTypeDisplayed ?? undefined,
      totalDeliveriesDisplayed: heapData.totalDeliveriesDisplayed ?? undefined,
      skippedDeliveriesDisplayed: heapData.skippedDeliveriesDisplayed ?? undefined,
      deliveryDatesDisplayed: heapData.deliveryDatesDisplayed ?? undefined,
      infoMessageDisplayed: heapData.infoMessageDisplayed ?? undefined,
      addedDeliveryDatePosition: heapData.addedDeliveryDatePosition ?? undefined,
      playVideoBy: heapData.playVideoBy ?? undefined,
      rewardsJoinOrigin: heapData.rewardsJoinOrigin ?? undefined,
      existingBouquet: heapData.existingBouquet ?? undefined,
      newBouquet: heapData.newBouquet ?? undefined,
      sentimentFeedback: heapData.sentimentFeedback
    };

    if (heapData.order?.addons.length) {
      // Greeting cards
      const greetingCard = heapData.order.addons.find((addon): boolean => addon.isGreetingCardType());
      params['greetingCardSku'] = greetingCard?.id ?? undefined;
      params['greetingCardPrice'] = greetingCard?.price ? (greetingCard?.price?.price / 100).toFixed(2) : undefined;
      params['greetingCardName'] = greetingCard?.name ?? undefined;

      // General addons except greeting cards
      const nonGreetingCardAddons = heapData.order.addons.filter((addon): boolean => !addon.isGreetingCardType());
      if (nonGreetingCardAddons.length) {
        params['totalAddonAdded'] = nonGreetingCardAddons.length ?? undefined;
        params['totalFreeAddon'] = nonGreetingCardAddons.filter((addon): boolean => addon?.price?.price === 0).length ?? undefined;
        params['totalPaidAddon'] = nonGreetingCardAddons.filter((addon): boolean => addon?.price?.price > 0).length ?? undefined;

        params['totalAddonPrice'] = nonGreetingCardAddons[0].price
          ? nonGreetingCardAddons.reduce((acc, addon): number => acc + addon?.price?.price / 100, 0)?.toFixed(2)
          : undefined;
        params['totalAddonList'] = nonGreetingCardAddons.map((addon): number => addon?.id).join(',') ?? undefined;
        params['totalAddonNameList'] = nonGreetingCardAddons.map((addon): string => addon?.name).join(',') ?? undefined;
      }
    }

    if (heapData.purchase?.discount) {
      const { discount } = heapData.purchase;
      params['discountCode'] = discount?.code ?? undefined;
      params['discountCodeApplication'] = discount?.code ? heapData.discountCodeApplication : undefined;
      params['discountCodeType'] = discount?.percentage !== null ? 'percent' : 'value';
      params['discountCodeDetail'] =
        discount?.percentage !== null ? `${discount?.percentage}%` : `${(discount?.amountPennies / 100).toFixed(2)}`;
    }

    if (heapData.purchase?.giftVouchers?.length) {
      const { giftVouchers } = heapData.purchase;
      const voucher = giftVouchers[giftVouchers.length - 1];
      params['rewardsVoucherCode'] = voucher?.code ?? undefined;
      params['rewardsVoucherValue'] = voucher?.initialValue ?? undefined;
      params['rewardsVoucherValuePoints'] = voucher?.amountUsed ?? undefined;
      params['rewardsVoucherApplication'] = heapData?.rewardsVoucherApplication ?? undefined;
    }

    if (heapData.addon) {
      params['addonSKU'] = heapData.addon ? heapData.addon.id : undefined;
      params['addonName'] = heapData.addon ? heapData.addon.name : undefined;
      params['addonId'] = heapData.addon ? heapData.addon.id : undefined;
      params['addonKind'] = heapData.addon ? heapData.addon.type : undefined;
      params['addonPosition'] = heapData.addon ? heapData.addonPosition : undefined;

      if (heapData.addon.price) {
        params['addOnPrice'] = (heapData.addon.price.price / 100).toFixed(2);
        params['addOnCurrency'] = heapData.addon.price.currency;
      }
    }

    if (heapData.loyaltyMembership) {
      params['rewardsAvailable'] = !!heapData?.loyaltyMembership.nextReward?.isRedeemable ?? false;
      params['claimedRewardsVouchers'] = heapData.loyaltyMembership.claimedRewards?.length ?? 0;
      params['claimedRewardsValue'] =
        heapData.loyaltyMembership.claimedRewards?.reduce((acc, reward): number => acc + reward.balance, 0) ?? 0;
      params['rewardsWidgetAvailablePointsValue'] = heapData.loyaltyMembership.points?.available ?? undefined;
      params['rewardsWidgetPendingPointsValue'] = heapData.loyaltyMembership.points?.pending ?? undefined;
      params['rewardsWidgetVoucherValue'] = heapData.loyaltyMembership.nextReward?.name.match(/\d+/)?.[0] ?? undefined;
      const { pointsToReachMilestone, milestone } = heapData.loyaltyMembership.nextReward;
      params['rewardsWidgetPointsToNextRewardValue'] = pointsToReachMilestone - milestone > 0 ? 0 : (pointsToReachMilestone ?? undefined);
    }

    let propertyObj = {};
    (registeredEvent.properties ?? []).forEach((propertyKey: string | number): void => {
      propertyObj[propertyKey] = params[propertyKey];
    });

    propertyObj = this.addGlobalPropertiesToEvents(propertyObj);

    this.logEvent(eventName, propertyObj);
  }

  addGlobalPropertiesToEvents(propertyObj: unknown): unknown {
    const config = this.configService.getConfig();
    propertyObj['brandId'] = config.brandId ?? undefined;
    propertyObj['shippingCountryId'] = this.getGlobalHeapProperties().shippingCountryId ?? undefined;
    propertyObj['locale'] = config.locale ?? undefined;
    propertyObj['site'] = config.countrySite ?? undefined;
    propertyObj['deviceFingerprint'] = this.userService.getFingerprint() ?? undefined;
    propertyObj['optimizelyUserId'] = this.windowRef.getCookie('OptimizelyUserID') ?? undefined;
    propertyObj['visitorType'] = this.returningCustomerService.isReturning ? 'returning' : 'new';
    propertyObj['languageDisplayed'] = config.locale ?? undefined;
    propertyObj['rewardsMember'] = this.userService.getUser()?.loyaltySchemeMembershipId !== undefined;
    return propertyObj;
  }

  clearEventProperties(): void {
    this.heap('clearEventProperties');
  }

  // The purpose of this function is tell us the different areas a user can reach the carousel from.
  reachedCarouselFrom(from: string, to: string): void {
    if (to.indexOf('checkout') > -1 && to !== 'checkout.payment' && to !== 'checkout.confirmation') {
      if (!from) {
        this.setGlobalEventProperty({ productFindingMethod: 'directAccess' });
      } else if (from === 'content') {
        this.setGlobalEventProperty({ productFindingMethod: 'SEO' });
      } else if (from === 'homepage') {
        this.setGlobalEventProperty({ productFindingMethod: 'homepage' });
      } else if (from.indexOf('checkout') > -1) {
        this.setGlobalEventProperty({ productFindingMethod: 'carousel' });
      } else if (from === 'account.occasions') {
        this.setGlobalEventProperty({ productFindingMethod: 'savedOccasion' });
      }
    }
    return undefined;
  }

  /**
   * Set currently running experiments to be used by heap
   * @param currentlyRunningExperiments
   */
  setExperimentsRunning(currentlyRunningExperiments: Experiment[]): void {
    this.experiments = currentlyRunningExperiments;
  }

  /**
   * Init
   */
  init(): Promise<unknown> {
    this.window.heap = this.window.heap ?? {};
    this.window.heap.appid = this.heapAccountId;
    this.window.heap.config = {
      disableTextCapture: false
    };

    this.initPromise =
      this.initPromise ??
      this.domUtilsService.loadScript(`https://cdn.heapanalytics.com/js/heap-${this.heapAccountId}.js`, 'heap').then((): void => {
        this.serviceInitialized = true;

        this.experiments.forEach((experiment): void => {
          this.logAdvancedEvent('experimentActive', {
            experiment
          });
        });
      });

    return this.initPromise;
  }
}
