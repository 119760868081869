import { Injectable } from '@angular/core';
import { DiscountModelService } from 'Checkout/models/discount-model.service';
import { Country } from 'Shared/classes/country';
import { Product } from 'Shared/classes/product';
import { Discount } from 'Shared/classes/discount';
import { BehaviorSubject } from 'rxjs';
import { GiftVoucher } from 'Shared/classes/gift-voucher';
import { StateService } from 'Shared/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  showDiscountBar$ = new BehaviorSubject<boolean>(false);

  public activeDiscountCode$ = new BehaviorSubject<Discount>(new Discount(''));
  public activeGiftVouchers$ = new BehaviorSubject<GiftVoucher[]>([]);

  constructor(private _discountModelService: DiscountModelService, private stateService: StateService) {}

  get activeDiscountCode(): Discount {
    return this.activeDiscountCode$.getValue();
  }

  set activeDiscountCode(value: Discount) {
    this.activeDiscountCode$.next(value);
  }

  get activeGiftVouchers(): GiftVoucher[] {
    return this.activeGiftVouchers$.getValue();
  }

  set activeGiftVouchers(value: GiftVoucher[]) {
    this.activeGiftVouchers$.next(value);
  }

  /**
   * Set show/hide value for discount bar
   * @param value
   */
  setShowDiscountBarValue(value: boolean): void {
    this.showDiscountBar$.next(value);
  }

  clearCache(): void {
    this._discountModelService.clearCache();
  }

  /**
   * Validate a code
   * @param discount
   * @param country
   * @param product
   * @param clearCache
   */
  check(discount: Discount, country: Country, product?: Product, clearCache?: boolean): Promise<Discount> {
    const params = this.stateService.getCurrent().params;

    return this._discountModelService.check(discount, country, product, clearCache, params);
  }

  /**
   * Reset discount code and gift vouchers
   */
  reset(): void {
    this.activeDiscountCode = null;
    this.activeGiftVouchers = [];
  }
}

export { Discount } from 'Shared/classes/discount';
