import { Order } from 'Shared/classes/order';
import { CurrencyCode, Price } from 'Shared/classes/price';
import { Discount } from 'Shared/classes/discount';
import * as clone from 'clone';
import { Credit } from 'Shared/classes/credit';
import { GiftVoucher, GiftVoucherRedemption } from 'Shared/classes/gift-voucher';
import { Address } from 'Shared/classes/address';

/**
 * Meta object for the purchase
 */
interface MetaObject {
  termsAccepted: boolean | undefined;
  termsDisplayed: boolean | undefined;
  buttonRef?: string;
  trackingCode?: string;
}

export class Purchase {
  id: number;
  token: string;
  orders: Order[] = [];
  state: string;
  currency: CurrencyCode;
  guestPasswordToken: string;
  source: string;
  credit: Credit;
  price: Price;
  discount: Discount;
  paymentIntentId: string;
  billingAddressId: number;
  billingAddressAttributes: Address | undefined;
  postalPreference: string;
  giftVoucher: GiftVoucher; // TODO: migrate to use giftVouchers
  giftVouchers: GiftVoucher[];
  giftVoucherRedemption: GiftVoucherRedemption;
  totalLoyaltyPoints: number;

  meta: MetaObject = {
    termsAccepted: false,
    termsDisplayed: false
  };

  get shippingOptionPrice(): Price {
    return this.orders.reduce(
      (_, order): Price => Order.calculateOrderTotal({ numberOfDeliveries: order.duration, shippingOption: order.shippingOption }),
      new Price(this.currency, 0, 0) // Provide an initial value for the reduce method
    );
  }

  /**
   * Set the state for the purchase
   * @param str
   */
  setState(str: string): void {
    this.state = (str || '').toLowerCase();
  }

  /**
   * Set the orders and re-index
   * @param orders
   */
  setOrders(orders: Order[]): void {
    this.orders = (orders || [])
      .slice()
      .sort((a, b): number => a.id - b.id)
      .map((order, index): Order => {
        order.indexInPurchase = index;
        return order;
      });
  }

  /**
   * Remove an order from the purchase
   * @param order
   */
  removeOrder(order: Order): void {
    this.orders = this.orders.filter((o): boolean => o.id !== order.id);
  }

  /**
   * Purchase contains at least one subscription
   */
  hasSubscriptionOrder(): boolean {
    return !!this.orders.find((order): boolean => order.isSubscription());
  }

  /**
   * @description Checks if the purchase has a gift card order
   * @returns {boolean}
   */
  hasDigitalGiftVoucherOrder(): boolean {
    return !!this.orders.find((order): boolean => order.product.isDigitalGiftVoucher());
  }

  /**
   * Clone the instance
   */
  clone(): Purchase {
    return clone(this);
  }
}
