/* eslint-disable */
export const environment = {
  "domain": "https://www.bloomstaging.dev",
  "countrySite": "en-gb",
  "stripeLocale": "en-GB",
  "site": "en",
  "locale": "en",
  "country": "gb",
  "languageCountryLocale": "en_GB",
  "paypalLocale": "en_GB",
  "klarnaLocale": "en-GB",
  "euroSymbolPosition": "start",
  "defaultShippingCountryId": 1,
  "environment": "performance",
  "production": false,
  "backendUrl": "/api",
  "backendRangeUrl": "https://www.bloomstaging.dev/range-discovery/api/:date/range",
  "mediaUrl": "https://media.bloomstaging.dev",
  "personalisationUrl": "https://personalisation.bloomstaging.dev",
  "addressSearchUrl": "https://services.postcodeanywhere.co.uk",
  "bugSnagEnabled": true,
  "bugSnagApiKey": "7ece8f9778372ac85cf5f52dc4f15006",
  "loqateApiKey": "WJ94-YP58-PE56-FZ42",
  "loqateAlternativeApiKey": "KG14-TB66-EM39-ZG61",
  "discountCode": "bw92h30v",
  "pusherKey": "e8b14a1e1cec3389cb4a",
  "carouselFile": "https://content.bloomandwild.com/carousel_automation/carousel_automation_staging.json",
  "gaId": "UA-38861532-4",
  "adwordsEnabled": true,
  "adwordsId": "978631465",
  "adwordsMidFunnel": {
    "enabled": true,
    "ViewHomepage": "kbovCO_fgOMYEKn20tID",
    "ViewItemList": "laz5CJmXi98YEKn20tID",
    "ViewItem": "c9FoCPm-i98YEKn20tID",
    "BeginCheckout": "c-zMCJXJht8YEKn20tID",
    "ViewCart": "UJjbCJ2Qh98YEKn20tID"
  },
  "adwordsConversion": [
    {
      "id": "978631465",
      "label": "uayCCM3g5VYQqfbS0gM"
    },
    {
      "id": "849893656",
      "label": "JBbACO6u6nMQmLKhlQM"
    },
    {
      "id": "849889316",
      "label": "Ip15CI6r6nMQpJChlQM"
    },
    {
      "id": "580513779",
      "label": "mJ8XCM6l9d8BEPPf55QC"
    }
  ],
  "digitalGenius": {
    "widgetId": "a405d7f9-0cd3-48fa-a734-4625fb79aef1",
    "env": "eu",
    "scriptUrl": "https://chat-assets.digitalgenius.com/dgchatinit.widget.v2.2.0.min.js",
    "suncoUrl": "https://chat.digitalgenius.com/sdk/dgchat.sunco.1.0.2.min.js",
    "lang": "en_GB",
    "isChatLauncherEnabled": true
  },
  "adwordsConversionSubs": [
    {
      "id": "978631465",
      "label": "uayCCM3g5VYQqfbS0gM"
    },
    {
      "id": "849893656",
      "label": "JBbACO6u6nMQmLKhlQM"
    },
    {
      "id": "849889316",
      "label": "Ip15CI6r6nMQpJChlQM"
    },
    {
      "id": "580513779",
      "label": "mJ8XCM6l9d8BEPPf55QC"
    }
  ],
  "optmiseContainer": "GTM-TNKF7CH",
  "braze": {
    "enabled": true,
    "apiKey": "20a5a191-3e3f-4f88-ac3f-0569e5addedf",
    "sdkEndpoint": "sdk.iad-01.braze.com"
  },
  "branchKey": "key_test_ifcD8d8HSVRkL3fwNG1H4onoDzfSJKOb",
  "trustedShopId": "XE4D65047C71326D9C868F29318F28A9B",
  "trustedShopEnabled": false,
  "contentServer": "https://content.bloomandwild.com/bloomandwild/en-gb/",
  "contentServerPreview": "https://i2vpsygoq8.execute-api.eu-west-1.amazonaws.com/preview-page?space_id=1d9ajmvjpuhw&slug=bloomandwild.com/",
  "contentServerFallback": "https://content.bloomandwild.com/bloomandwild/en-gb/",
  "contentServerFallbackPreview": "",
  "contentFallbackDomain": "",
  "contentFallbackHreflangs": [],
  "contentHrefLangs": [
    "en",
    "en-gb"
  ],
  "rewriteLinksThatIncludeDomain": [
    "https://www.bloomandwild.com",
    "https://bloomandwild.com",
    "http://www.bloomandwild.com",
    "http://bloomandwild.com"
  ],
  "mentionMeId": "mm0ee1ada4",
  "mentionMeSubsId": "mm81a44f2e",
  "mentionMeUrl": "https://tag-demo.mention-me.com/api/v2/",
  "mentionMeAPI": "https://demo.mention-me.com/api/",
  "hotjarEnabled": false,
  "hotjarSiteId": "1046468",
  "facebookMarketingId": "1478504809093500",
  "tiktokMarketingId": "",
  "tiktokPixelEnabled": false,
  "pinterestTagId": "2614256225444STAGING",
  "appsFlyerBannerKey": "49483f94-951b-4eca-a494-de273102d86c",
  "appsFlyerBannerEnabled": true,
  "appsFlyerPBAKey": "2523e3cc-82ee-4475-8007-43303bba2dbb",
  "snapchatEnabled": false,
  "snapchatAccountKey": "NO-ID-USED-ON-STAGING",
  "heapEnabled": true,
  "heapAccountId": "2849754638",
  "optimizelySDK": "U9W3K7CAPUcB6u9ce4dVc",
  "optimizelyEnabled": true,
  "optimizelyExperimentKeys": {
    "sku": "bw_uk_sku_price_tests",
    "shipping": "bw_uk_shipping_price_tests",
    "shippingMethod": "bw_uk_shipping_method_tests",
    "navigation": "bw_uk_web_navigation_tests",
    "range": "bw_uk_web_range_tests",
    "addonRange": "bw_uk_web_addon_range_tests",
    "contentCard": "bw_uk_web_content_card_tests"
  },
  "zyperApiUrl": "https://api-staging.zyper.com/client/v1/analytics/conversions/",
  "zyperTrackingEnabled": true,
  "zyperAuthkey": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNTU2OTU0NDc5LCJqdGkiOiI0ZTAxY2ExMDMyMDM0MThkYWI0Y2Y5OWE2N2IxNGIyYyIsInVzZXJfaWQiOjYzOTQsInJvbGUiOiJBcGlVc2VyIn0.HoEBZ-V6YKXvqGcXQ0AhVRx95McyDXu1wdWaJmVw_FQ",
  "zyperBrandId": 3,
  "zyperCampaignId": 3,
  "quoraEnabled": false,
  "quoraTagId": "cbeac441494046cb835cd39efb80e662-STAGING",
  "tvSquaredUrl": "https://collector-4402-STAGING.tvsquared.com",
  "tvSquaredEnabled": false,
  "tvSquaredId": "TV-54549072-1",
  "drtvUrl": "https://trck.spoteffects.net/analytics",
  "drtvId": 0,
  "drTvEnabled": false,
  "gtagConversionId": "AW-978631465",
  "gtagGA4ConversionId": "G-269Y17N5W1",
  "gtmId": "GTM-M75DZ4",
  "gaDimensions": {
    "bucketNumber": 11,
    "userRegistered": 9,
    "loggedIn": 8,
    "contentSegment": 12,
    "carouselSegment": 13,
    "isCustomerDelight": 10,
    "deliveryCountry": 16,
    "deliveryCountrySelected": 18,
    "languageDisplayed": 19
  },
  "nowShippingBasePostcode": "SE11 5JH",
  "nowShippingOption": 2592,
  "shippingCountries": {
    "1": {
      "experimentsRolledOut": {
        "LAZY_GRID": 1
      }
    },
    "2": {
      "experimentsRolledOut": {}
    },
    "5": {
      "experimentsRolledOut": {}
    },
    "6": {
      "experimentsRolledOut": {}
    },
    "7": {
      "experimentsRolledOut": {}
    }
  },
  "contentfulQueryKey": "jAWsB0rynxH5_3WIenu4QLHrIoGHUQL00-Q7ziOq_Os",
  "contentfulPreviewKey": "2_3dR9tIyHZe_9CCiDTeuY0cDLPGFkVxIdEDn5FJJEk",
  "contentfulSpaceId": "1d9ajmvjpuhw",
  "contentfulParameters": {
    "contentType": "blogPage",
    "date": "publishedDate",
    "contentLocale": "en-gb/",
    "blogLimit": 12,
    "fallback": "https://content.bloomandwild.com/bloomandwild/en-gb/",
    "enableMajorLandingPages": true
  },
  "subsPlatformAllowedUrls": [
    "bloomandwild.com"
  ],
  "keyIvrUrl": "https://agent-assist-st.keyivr.co.uk/",
  "zendeskKey": "ef9f27fb-91dc-406d-92b0-3681d963a237",
  "zendeskChatOffset": 0,
  "zendeskLocale": "en-gb",
  "zendeskDefaultDepartment": 1817740133,
  "facebookAppId": "1589783424605588",
  "occasionsOffers": {
    "add3credit5": {
      "requiredCount": 3,
      "code": "5c8fw87913fza",
      "amount": 500,
      "currency": "GBP"
    }
  },
  "rakutenEnabled": false,
  "inflcrPixelEnabled": true,
  "inflcrOfferId": 465,
  "partnerizeBrandId": "0",
  "partnerizeTagId": "0",
  "partnerizeCampaignMatrix": "1011l5721",
  "partnerizeEnabled": false,
  "bingUetEnabled": true,
  "bingUetTagId": "staging_5013323",
  "brandShort": "bandw",
  "brandId": "bloom_and_wild",
  "brandName": "Bloom & Wild",
  "paypalBrandName": "Bloom & Wild",
  "brandIdNumber": 1,
  "cookieBotId": "74777e5b-dc33-481d-b216-2239d5436e2c",
  "googleClientId": "325986230024-kmih97tp0ho2p31kpad62lmq9viblsul.apps.googleusercontent.com",
  "countryIdToSite": [
    {
      "site": "https://www.bloomstaging.dev/en-at",
      "countryIds": [
        7
      ],
      "locale": "en"
    },
    {
      "site": "https://www.bloomstaging.dev/de-at",
      "countryIds": [
        7
      ],
      "locale": "de"
    },
    {
      "site": "https://de.bloomstaging.dev",
      "countryIds": [
        6
      ],
      "locale": "de"
    },
    {
      "site": "https://fr.bloomstaging.dev",
      "countryIds": [
        5
      ],
      "locale": "fr"
    },
    {
      "site": "https://www.bloomstaging.dev",
      "countryIds": [
        1,
        2,
        5,
        6
      ],
      "locale": "en"
    }
  ]
};