import { FormControl } from '@angular/forms';
import { ContentSegment } from 'Shared/services/segment.service';

/**
 * @description
 */
export class KnownExperiment {
  id?: string;
  name: string;
  variants: number[];
  variant: number;

  constructor(name: string, variants: number[], variant: number = 0) {
    this.name = name;
    this.variants = variants;
    this.variant = variant;
  }
}

export interface KnownSegment extends ContentSegment {
  isActive: boolean;
}

export interface StagingHelperFormControls {
  newApiURL: FormControl<string>;
  purchaseId: FormControl<string>;
  skuId: FormControl<string>;
  [key: string]: FormControl<string | boolean>;
}

export interface StagingHelperSections {
  segments: boolean;
  experiments: boolean;
  pbsDetails: boolean;
  contentfulEditor: boolean;
  checkoutShortcut: boolean;
  exampleSkus: boolean;
  confirmationShortcut: boolean;
  stagingSites: boolean;
  branchSites: boolean;
}

export interface StagingSite {
  name: string;
  sites: ClientUrl[];
}

export interface ClientUrl {
  flag: string;
  stagingUrl: string;
  featureBranchUrl: string;
}

export const STAGING_SITES: StagingSite[] = [
  {
    name: 'Bloomandwild',
    sites: [
      { flag: '🇬🇧', stagingUrl: 'https://www.bloomdev.org/', featureBranchUrl: 'https://fe-staging-bandw-com.bloomdev.org/' },
      { flag: '🇩🇪', stagingUrl: 'https://de.bloomdev.org/', featureBranchUrl: 'https://fe-staging-bandw-de.bloomdev.org/' },
      {
        flag: '🇦🇹 🇬🇧',
        stagingUrl: 'https://www.bloomdev.org/en-at/',
        featureBranchUrl: ' https://fe-staging-bandw-com.bloomdev.org/en-at/'
      },
      { flag: '🇦🇹 🇩🇪', stagingUrl: 'https://de.bloomdev.org/de-at/', featureBranchUrl: 'https://fe-staging-bandw-com.bloomdev.org/de-at/' }
    ]
  },
  {
    name: 'Bloomon',
    sites: [
      { flag: '🇬🇧', stagingUrl: 'https://bloomon-uk.bloomdev.org/', featureBranchUrl: 'https://fe-staging-bloomon-uk.bloomdev.org//' },
      { flag: '🇩🇪', stagingUrl: 'https://bloomon-de.bloomdev.org/', featureBranchUrl: 'https://fe-staging-bloomon-de.bloomdev.org/' },
      { flag: '🇧🇪', stagingUrl: 'https://bloomon-be.bloomdev.org/', featureBranchUrl: 'https://fe-staging-bloomon-be.bloomdev.org/' },
      { flag: '🇩🇰', stagingUrl: 'https://bloomon-dk.bloomdev.org/', featureBranchUrl: 'https://fe-staging-bloomon-dk.bloomdev.org/' },
      { flag: '🇳🇱', stagingUrl: 'https://bloomon-nl.bloomdev.org/', featureBranchUrl: 'https://fe-staging-bloomon-nl.bloomdev.org/' }
    ]
  }
];

export type ProductType = 'letterbox' | 'plants' | 'bundle' | 'subscription';
